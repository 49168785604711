var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "purchaseRequisition container" },
    [
      !_vm.isShowEdit
        ? _c("div", { staticClass: "part" }, [
            _c("div", { staticClass: "actions_part clearfix" }, [
              _c("div", { staticClass: "actions_wrap" }, [
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("请求编号：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.purchaseRequestCode,
                        callback: function($$v) {
                          _vm.purchaseRequestCode = $$v
                        },
                        expression: "purchaseRequestCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [
                      _vm._v("销售订单编号：")
                    ]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.saleOrderId,
                        callback: function($$v) {
                          _vm.saleOrderId = $$v
                        },
                        expression: "saleOrderId"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("产品编号：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.productCode,
                        callback: function($$v) {
                          _vm.productCode = $$v
                        },
                        expression: "productCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("物料编号：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.materialCode,
                        callback: function($$v) {
                          _vm.materialCode = $$v
                        },
                        expression: "materialCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("请求类型：")]),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10",
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.searchHandle },
                        model: {
                          value: _vm.purchaseType,
                          callback: function($$v) {
                            _vm.purchaseType = $$v
                          },
                          expression: "purchaseType"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "全部", value: "" }
                        }),
                        _vm._l(_vm.requestTypeList, function(item) {
                          return _c("el-option", {
                            key: item.dictItemValue,
                            attrs: {
                              label: item.dictItemName,
                              value: item.dictItemValue
                            }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("创建日期：")]),
                    _c("el-date-picker", {
                      attrs: {
                        "unlink-panels": "",
                        type: "daterange",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      on: { change: _vm.dateValChange },
                      model: {
                        value: _vm.dateVal,
                        callback: function($$v) {
                          _vm.dateVal = $$v
                        },
                        expression: "dateVal"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "actions_btn_wrap down t_right" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.excelDownload }
                    },
                    [_vm._v("导出Excel")]
                  ),
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-search",
                      circle: "",
                      title: "搜索"
                    },
                    on: { click: _vm.searchHandle }
                  }),
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-plus",
                      circle: "",
                      title: "添加"
                    },
                    on: { click: _vm.addHandle }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "table_part clearfix" },
              [
                _c(
                  "el-tabs",
                  {
                    staticClass: "subnav_wrap hidden",
                    on: { "tab-click": _vm.switchTabHandle },
                    model: {
                      value: _vm.activeName,
                      callback: function($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName"
                    }
                  },
                  _vm._l(_vm.subNavList, function(item) {
                    return _c(
                      "el-tab-pane",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.tableLoading,
                            expression: "tableLoading"
                          }
                        ],
                        key: item.value,
                        attrs: { label: item.label, name: item.value }
                      },
                      [
                        _c(
                          "el-table",
                          {
                            ref: "multipleTable" + _vm.activeName,
                            refInFor: true,
                            staticClass: "table_list",
                            attrs: {
                              data: _vm.requestList,
                              "expand-row-keys": _vm.expands,
                              "row-key": "id",
                              border: "",
                              fit: "",
                              stripe: ""
                            },
                            on: {
                              "row-click": _vm.rowClick,
                              "expand-change": _vm.rowClick
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: { type: "expand" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "actions_wrap mb20 clearfix"
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "fr",
                                                attrs: { type: "primary" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.childExcelDownload(
                                                      scope.row
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("导出Excel")]
                                            ),
                                            scope.row.prStatus === "Draft" ||
                                            scope.row.prStatus === "Rejected"
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "fr mr10",
                                                    attrs: { type: "primary" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.editHandle(
                                                          scope.$index,
                                                          scope.row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("更新导入Excel")]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c(
                                          "pl-table",
                                          {
                                            ref:
                                              "childMultipleTable_" +
                                              scope.row.id,
                                            refInFor: true,
                                            staticClass: "table_list",
                                            staticStyle: {
                                              width: "100%",
                                              height: "100%"
                                            },
                                            attrs: {
                                              data: scope.row.priList,
                                              "max-height": 700,
                                              "use-virtual": true,
                                              "row-height": 90,
                                              border: "",
                                              fit: ""
                                            }
                                          },
                                          [
                                            _c("el-table-column", {
                                              attrs: {
                                                fixed: "",
                                                label: "行号",
                                                "min-width": "100"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(row.sn)
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                "show-overflow-tooltip": true,
                                                fixed: "",
                                                label: "物料编号",
                                                "min-width": "180",
                                                "class-name": "marpno_wuliao"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.materialCode
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "产品编号",
                                                "min-width": "180",
                                                "class-name": "marpno_wuliao"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "ellipsis",
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            },
                                                            attrs: {
                                                              title:
                                                                row.productCode
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.productCode
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                label: "期望交期",
                                                "min-width": "180",
                                                "class-name": "marpno_wuliao"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.requiredDate
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _vm.activeName == 0 ||
                                            _vm.activeName == "Approved"
                                              ? _c("pl-table-column", {
                                                  attrs: {
                                                    label: "工厂交期",
                                                    "min-width": "180",
                                                    "class-name":
                                                      "marpno_wuliao"
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var row = ref.row
                                                          return [
                                                            _c(
                                                              "p",
                                                              {
                                                                class: {
                                                                  isThrough:
                                                                    row.cancelStatus
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    row.offeredDate
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              : _vm._e(),
                                            _c("pl-table-column", {
                                              attrs: {
                                                label: "最晚调拨时间",
                                                "min-width": "180",
                                                "class-name": "marpno_wuliao"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.latestTransferDate
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                label: "请求数量",
                                                "min-width": "180",
                                                "class-name": "marpno_wuliao"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        row.purchaseMeasurementUnit ==
                                                        "米"
                                                          ? _c("span", [
                                                              _c(
                                                                "p",
                                                                {
                                                                  class: {
                                                                    isThrough:
                                                                      row.cancelStatus
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      (row.requiredPackages
                                                                        ? row.requiredPackages
                                                                        : 0) +
                                                                        "包 " +
                                                                        (row.requiredQuantityPerPackage
                                                                          ? row.requiredQuantityPerPackage
                                                                          : 0) +
                                                                        "米/包 " +
                                                                        (row.requiredQuantity
                                                                          ? row.requiredQuantity
                                                                          : 0) +
                                                                        "米"
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ])
                                                          : _c("span", [
                                                              _c(
                                                                "p",
                                                                {
                                                                  class: {
                                                                    isThrough:
                                                                      row.cancelStatus
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      (row.requiredQuantity
                                                                        ? row.requiredQuantity
                                                                        : 0) +
                                                                        (row.purchaseMeasurementUnit
                                                                          ? row.purchaseMeasurementUnit
                                                                          : "条")
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ])
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _vm.activeName == 0 ||
                                            _vm.activeName == "Approved"
                                              ? _c("pl-table-column", {
                                                  attrs: {
                                                    label: "采购数量",
                                                    "min-width": "180",
                                                    "class-name":
                                                      "marpno_wuliao"
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var row = ref.row
                                                          return [
                                                            row.purchaseMeasurementUnit ==
                                                            "米"
                                                              ? _c("span", [
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      class: {
                                                                        isThrough:
                                                                          row.cancelStatus
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          (row.offeredPackages
                                                                            ? row.offeredPackages
                                                                            : 0) +
                                                                            "包 " +
                                                                            (row.offeredQuantityPerPackage
                                                                              ? row.offeredQuantityPerPackage
                                                                              : 0) +
                                                                            "米/包 " +
                                                                            (row.offeredQuantity
                                                                              ? row.offeredQuantity
                                                                              : 0) +
                                                                            "米"
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                ])
                                                              : _c("span", [
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      class: {
                                                                        isThrough:
                                                                          row.cancelStatus
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          (row.offeredQuantity
                                                                            ? row.offeredQuantity
                                                                            : 0) +
                                                                            (row.purchaseMeasurementUnit
                                                                              ? row.purchaseMeasurementUnit
                                                                              : "条")
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                ])
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              : _vm._e(),
                                            _c("pl-table-column", {
                                              attrs: {
                                                label: "库位",
                                                "min-width": "180",
                                                "class-name": "marpno_wuliao"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.subInventoryName
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                label: "销售订单编号",
                                                "min-width": "180",
                                                "class-name": "marpno_wuliao"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.saleOrderId
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                label: "采购订单编号",
                                                "min-width": "180",
                                                "class-name": "marpno_wuliao"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.purchaseOrderId
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                label: "确认书编号",
                                                "min-width": "180",
                                                "class-name": "marpno_wuliao"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.confirmNumbers
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                label: "特殊要求",
                                                "min-width": "180",
                                                "class-name": "marpno_wuliao"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.specialRequire
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                label: "货妥数量",
                                                "min-width": "100",
                                                "class-name": "marpno_wuliao"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.cargoQuantity
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            scope.row.prStatus === "Approved"
                                              ? _c("pl-table-column", {
                                                  attrs: {
                                                    label: "状态",
                                                    "min-width": "100"
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var row = ref.row
                                                          return [
                                                            _c(
                                                              "p",
                                                              {
                                                                class: {
                                                                  isThrough:
                                                                    row.cancelStatus
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    row.cancelStatus ===
                                                                      true
                                                                      ? "已取消"
                                                                      : "正常"
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              : _vm._e(),
                                            _c("pl-table-column", {
                                              attrs: {
                                                label: "备注",
                                                "min-width": "180"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(row.remark)
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                label: "原确认书编号",
                                                "min-width": "180"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              isThrough:
                                                                row.cancelStatus
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.originalConfirmNum
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            scope.row.prStatus === "Approved"
                                              ? _c("pl-table-column", {
                                                  attrs: {
                                                    label: "操作",
                                                    fixed: "right",
                                                    "min-width": "180"
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var row = ref.row
                                                          var $index =
                                                            ref.$index
                                                          return [
                                                            _c("el-button", {
                                                              staticClass:
                                                                "el-icon-document-delete",
                                                              attrs: {
                                                                disabled:
                                                                  row.purchaseOrderId ||
                                                                  row.cancelStatus ===
                                                                    true,
                                                                type: "danger",
                                                                circle: "",
                                                                size: "mini",
                                                                title: "撤销"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.stopPropagation()
                                                                  return _vm.revokeHandle(
                                                                    $index,
                                                                    row
                                                                  )
                                                                }
                                                              }
                                                            })
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              : _vm._e(),
                                            scope.row.prStatus === "Draft"
                                              ? _c("pl-table-column", {
                                                  attrs: {
                                                    label: "操作",
                                                    fixed: "right",
                                                    "min-width": "180"
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var row = ref.row
                                                          var $index =
                                                            ref.$index
                                                          return [
                                                            _c("el-button", {
                                                              staticClass:
                                                                "el-icon-delete",
                                                              attrs: {
                                                                type: "danger",
                                                                circle: "",
                                                                size: "mini",
                                                                title: "删除"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.stopPropagation()
                                                                  return _vm.editDeleteHandle(
                                                                    $index,
                                                                    row
                                                                  )
                                                                }
                                                              }
                                                            })
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "请求编号" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.purchaseRequestCode) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "请求类型" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.purchaseTypeName) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "入库仓位" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.virtualInventoryName) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "创建时间" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.createTime) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "申请人" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.applicantName) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "凭证状态" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      var $index = ref.$index
                                      return [
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "right",
                                              width: "300",
                                              trigger: "hover",
                                              "open-delay": "1000"
                                            },
                                            on: {
                                              show: function($event) {
                                                return _vm.checkApprovalProcess(
                                                  row,
                                                  $index
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "i",
                                                { staticClass: "block" },
                                                [
                                                  _vm._v(
                                                    "\n                      审批人：\n                      "
                                                  ),
                                                  _c("em", [
                                                    _vm._v(_vm._s(row.fullName))
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "i",
                                                {
                                                  staticClass: "block mt10 mb10"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      审批时间：\n                      "
                                                  ),
                                                  _c("em", [
                                                    _vm._v(
                                                      _vm._s(row.approvalTime)
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "i",
                                                { staticClass: "block" },
                                                [
                                                  _vm._v(
                                                    "\n                      审批意见：\n                      "
                                                  ),
                                                  _c("em", [
                                                    _vm._v(_vm._s(row.content))
                                                  ])
                                                ]
                                              )
                                            ]),
                                            _c(
                                              "el-tag",
                                              {
                                                staticClass: "pointer",
                                                attrs: {
                                                  slot: "reference",
                                                  type: _vm._f(
                                                    "prStatusFilter"
                                                  )(row.prStatus)
                                                },
                                                slot: "reference"
                                              },
                                              [_vm._v(_vm._s(row.prStatusName))]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "操作" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      var $index = ref.$index
                                      return [
                                        row.prStatus === "Draft" ||
                                        row.prStatus === "Rejected"
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-button", {
                                                  staticClass: "el-icon-edit",
                                                  attrs: {
                                                    type: "primary",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "编辑"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.editHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c("el-button", {
                                                  staticClass: "el-icon-check",
                                                  attrs: {
                                                    type: "success",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "提交审批"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.submitHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c("el-button", {
                                                  staticClass: "el-icon-delete",
                                                  attrs: {
                                                    type: "danger",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "删除"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.deleteHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        row.prStatus === "Approved"
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-button", {
                                                  staticClass:
                                                    "el-icon-switch-button",
                                                  attrs: {
                                                    type: "danger",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "关闭"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.closeHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                }),
                                                row.purchaseTypeName ===
                                                "采购申请差异单"
                                                  ? _c("el-button", {
                                                      staticClass:
                                                        "el-icon-s-promotion",
                                                      attrs: {
                                                        type: "warning",
                                                        circle: "",
                                                        size: "mini",
                                                        title: "推送"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.pushHandle(
                                                            $index,
                                                            row
                                                          )
                                                        }
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        row.prStatus === "Close"
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-button", {
                                                  staticClass:
                                                    "el-icon-switch-button",
                                                  attrs: {
                                                    type: "danger",
                                                    disabled: "",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "关闭"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        row.prStatus === "Terminated"
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-button", {
                                                  staticClass: "el-icon-delete",
                                                  attrs: {
                                                    type: "danger",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "删除"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.deleteHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pagination_wrap fr" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.currentPage,
                        "page-sizes": [10, 50],
                        "page-size": _vm.pageSize,
                        total: _vm.total,
                        layout: "sizes, total, prev, pager, next"
                      },
                      on: {
                        "update:currentPage": function($event) {
                          _vm.currentPage = $event
                        },
                        "update:current-page": function($event) {
                          _vm.currentPage = $event
                        },
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.editPartLoading,
                  expression: "editPartLoading"
                }
              ],
              staticClass: "edit_part"
            },
            [
              _c(
                "div",
                { staticClass: "clearfix mb10" },
                [
                  _c(
                    "div",
                    { staticClass: "display_ib mr10 mb10" },
                    [
                      _vm._m(0),
                      _c(
                        "el-select",
                        {
                          staticClass: "select_single w200 mr10",
                          attrs: {
                            disabled: !_vm.isEmptyData,
                            placeholder: "请选择"
                          },
                          on: { change: _vm.purchaseTypeSelect },
                          model: {
                            value: _vm.editData.purchaseType,
                            callback: function($$v) {
                              _vm.$set(_vm.editData, "purchaseType", $$v)
                            },
                            expression: "editData.purchaseType"
                          }
                        },
                        [
                          _c("el-option", {
                            key: "",
                            attrs: { label: "请选择", value: "" }
                          }),
                          _vm._l(_vm.requestTypeList, function(item) {
                            return _c("el-option", {
                              key: item.dictItemValue,
                              attrs: {
                                label: item.dictItemName,
                                value: item.dictItemValue
                              }
                            })
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "display_ib mr10 mb10" },
                    [
                      _vm._m(1),
                      _vm.isDisabledInventoryPull == true
                        ? _c(
                            "el-select",
                            {
                              staticClass: "select_single w200 mr10",
                              attrs: {
                                disabled: _vm.isDisabledInventoryPull,
                                placeholder: "请选择"
                              },
                              on: { change: _vm.virtualInventorySelect },
                              model: {
                                value: _vm.editData.virtualInventoryId,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.editData,
                                    "virtualInventoryId",
                                    $$v
                                  )
                                },
                                expression: "editData.virtualInventoryId"
                              }
                            },
                            [
                              _c("el-option", {
                                key: "",
                                attrs: { label: "请选择dis", value: "" }
                              }),
                              _vm._l(_vm.virtualInventoryList, function(item) {
                                return _c("el-option", {
                                  key: item.index,
                                  attrs: { label: item.name, value: item.index }
                                })
                              })
                            ],
                            2
                          )
                        : _c(
                            "el-select",
                            {
                              staticClass: "select_single w200 mr10",
                              attrs: {
                                disabled: !_vm.isEmptyData,
                                placeholder: "请选择"
                              },
                              on: { change: _vm.virtualInventorySelect },
                              model: {
                                value: _vm.editData.virtualInventoryId,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.editData,
                                    "virtualInventoryId",
                                    $$v
                                  )
                                },
                                expression: "editData.virtualInventoryId"
                              }
                            },
                            [
                              _c("el-option", {
                                key: "",
                                attrs: { label: "请选择", value: "" }
                              }),
                              _vm._l(_vm.virtualInventoryList, function(item) {
                                return _c("el-option", {
                                  key: item.index,
                                  attrs: { label: item.name, value: item.index }
                                })
                              })
                            ],
                            2
                          )
                    ],
                    1
                  ),
                  _vm.isPurchaseBySale
                    ? _c(
                        "div",
                        { staticClass: "display_ib mr10 mb10" },
                        [
                          _c("span", { staticClass: "tag" }, [
                            _vm._v("销售订单编号：")
                          ]),
                          _c("el-input", {
                            staticClass: "input_single w200 mr10",
                            attrs: {
                              disabled: !_vm.isEmptyData,
                              placeholder: "请输入"
                            },
                            model: {
                              value: _vm.editData.saleOrderId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.editData,
                                  "saleOrderId",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "editData.saleOrderId"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("el-button", {
                    staticClass: "mr10 mb10",
                    attrs: {
                      disabled: !_vm.isEmptyData || _vm.isDisabledPull,
                      size: "small",
                      type: "primary",
                      icon: "el-icon-refresh-right",
                      circle: "",
                      title: "拉取数据"
                    },
                    on: { click: _vm.pullDataHandle }
                  }),
                  _vm.isPurchaseBySale
                    ? _c("div", { staticClass: "display_ib mr10" }, [
                        _c("span", { staticClass: "tag" }, [
                          _vm._v("客户名称：")
                        ]),
                        _c("span", { staticClass: "tag" }, [
                          _vm._v(_vm._s(_vm.editData.customerName))
                        ])
                      ])
                    : _vm._e(),
                  !_vm.isDisabledInventoryPull
                    ? _c(
                        "a",
                        {
                          staticClass: "link inline_block lh36 fr ml10",
                          attrs: { type: "primary" },
                          on: { click: _vm.exportExcelTemplate }
                        },
                        [_vm._v("采购申请导入模板")]
                      )
                    : _vm._e(),
                  !_vm.isDisabledInventoryPull
                    ? _c("el-button", {
                        staticClass: "fr",
                        attrs: {
                          size: "small",
                          type: "primary",
                          icon: "el-icon-plus",
                          circle: "",
                          title: "添加"
                        },
                        on: { click: _vm.addEditHandle }
                      })
                    : _vm._e()
                ],
                1
              ),
              !_vm.isDisabledInventoryPull
                ? _c("upload-excel-component", {
                    staticClass: "mb20",
                    attrs: {
                      "on-success": _vm.importExcelSuccess,
                      "before-upload": _vm.importExcelBeforeUpload
                    }
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "hidden" },
                [
                  _c("editTable", {
                    ref: "editTable",
                    attrs: {
                      "table-list": _vm.editData.pricList,
                      total:
                        _vm.editData.pricList && _vm.editData.pricList.length
                          ? _vm.editData.pricList.length
                          : 0,
                      "table-leader-fields-list": _vm.leaderFieldsList,
                      "table-tail-fields-list": _vm.tailFieldsList,
                      "table-fields-list": _vm.editFieldsList,
                      "select-remote-loading": _vm.selectRemoteLoading
                    },
                    on: {
                      deleteHandle: _vm.deleteEditItemHandle,
                      inputChange: _vm.inputChange,
                      inputBlur: _vm.inputBlur,
                      selectChange: _vm.selectChange,
                      selectVisibleChange: _vm.selectVisibleChange,
                      unitNumberInputChange: _vm.unitNumberInputChange,
                      selectRemoteMethod: _vm.selectRemoteMethod,
                      selectRemoteChange: _vm.selectRemoteChange,
                      selectRemoteClick: _vm.selectRemoteClick
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "edit_btn_wrap mt20 fr" },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.cancelEditHandle($event)
                            }
                          }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "preventReClick",
                              rawName: "v-preventReClick"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.updateEditHandle("save")
                            }
                          }
                        },
                        [_vm._v("保存")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "preventReClick",
                              rawName: "v-preventReClick"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.updateEditHandle("submit")
                            }
                          }
                        },
                        [_vm._v("提交审批")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
      _vm.dialogVisible
        ? _c("Dialog", {
            attrs: {
              dialogDesc: _vm.dialogDesc,
              dialogVisible: _vm.dialogVisible
            },
            on: {
              dialogConfirmHandle: _vm.dialogConfirmHandle,
              dialogCancelHandle: _vm.dialogCancelHandle
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tag" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("请求类型：")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tag" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("入库仓位：")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }