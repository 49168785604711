// 采购申请
import http from "../http";
import axios from "axios";

/**
 * 关闭该条订单
 */
export const closeTransferOrder = (id) => {
  return http({
    method: "put",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_SCM_URL + `purchaserequest/v1/purchaseUpdateDocumentsStatus/${id}`,
  });
};

/**
 * 申请采购
 * 分页查询采购计划管理查询
 */
export const purchaseRequestList = (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `purchaserequest/v1/purchaseRequestAndItems/searchItemByCondition?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};

/**
 * 草稿的分页查询采购计划管理查询
 */
export const purchaseRequestDraftList = (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `purchaserequest/v1/purchaseRequestAndItems/searchAllItem?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};

/**
 * 列表提交审批新增采购计划管理信息
 */
export const submitPurchaseRequest = (id, data) => {
  return http({
    method: "put",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_SCM_URL + `purchaserequest/v1/purchaserequests/${id}`,
    data
  });
};

/**
 * 删除采购计划管理信息
 */
export const deletePurchaseRequest = (id) => {
  return http({
    method: "delete",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_SCM_URL + `purchaserequest/v1/purchaserequests/${id}`,
  });
};
/**
 * 删除采购申请项的一行
 */
export const deletePurchaseRequestitems = (id) => {
  return http({
    method: "delete",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_SCM_URL + `purchaserequestitem/v1/purchaserequestitems/${id}`,
  });
};


/**
 *推送采购申请项的一行
 */
 export const pushPurchaseRequestitem = (purchaseRequestCode,userName) => {
  return http({
    method: "get",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_SCM_URL + `purchaserequest/v1/differencePushedToGroupAdapter?purchaseRequestCode=${purchaseRequestCode}&userName=${userName}`,
  });
};



/**
 * 撤销PR子项
 */
export const revokePurchaseRequestItem = (uuid) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `purchaserequest/v1/cancelPurchaseRequestItem?uuid=${uuid}`,
  });
};

/**
 * 根据id查询采购计划管理信息
 */
export const searchPurchaseRequest = (id) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `purchaserequest/v1/purchaserequestanditems/${id}`,
  });
};

/**
 * 新增采购计划拉去以销定采数据管理信息
 */
// export const pullPurchaseRequest = (data) => {
//   return http({
//     method: "post",
//     url: process.env.VUE_APP_OMS_URL + `saleorder/oms/saleorders/saleorderanditem`,
//     data
//   });
// };
const CommonUnit = {individual :'个',Box:'箱',Kg:'公斤',onePieces:'件',Cover:'套',Piece:'条',Book:'本',Meter:'米',Package:'包'}
export const getSoItem = (params) => {
  let data = {
    orderBy: [],
    query: [
      { fieldName: 'soNo', opr: 'eq', value: params.saleOrderId },
      { fieldName: 'objectNo', opr: 'eq', value: params.saleOrderId },
      { fieldName: 'invoiceNo', opr: 'eq', value: params.saleOrderId },
    ],
    subTotal: [],
    groupBy: []
  };
  let result = http({
    method: 'post',
    url: `${process.env.VUE_APP_OMS_URL}soItem/v2/index?currentPage=1&pageSize=9999`,
    data
  });
  result._then = result.then
  result.then = (cb) => {
    result._then((res) => {
      let r = res.data.records
      r = r.map(item => {
        item.productCode = item.productNo // 产品编号
        item.materialCode = item.materialNo // 物料编号
        item.saleOrderId = item.objectNo // SO单号
        item.purchaseMeasurementUnit = CommonUnit[item.unit] // 单位字段
        let interval = item.companyRemarks != null  && item.companyRemarks != '' ? '/' : '';
        item.remark = item.remarks + interval + item.companyRemarks // 备注 = 备注 + 拼接备注
        return item;
      });
      cb(r);
    });
  }
  return result;
}
export const getSo = (params) => {
  let data = { orderBy: [], query: [{ fieldName: 'invoiceNo', opr: 'eq', value: params.saleOrderId }], subTotal: [], groupBy: [] }
  let result = http({
    method: 'post',
    url: `${process.env.VUE_APP_OMS_URL}so/v2/index?currentPage=1&pageSize=20`,
    data
  });
  result._then = result.then;
  result.then = (cb) => {
    result._then((res) => {
      let r = res.data.records;
      r = r.map(item => {
        item.customerName = item.businessCustomerName;
        item.customerId = item.businessCustomerNo;
        return item;
      })[0];
      cb(res);
    });
  }
  return result;
}
export const pullPurchaseRequest =  async (params) => {
  let res = await getSo(params);
  console.log('M-PR拉取SO', res);
  let result = {
    data: res.data.records.length > 0 ? res.data.records[0] : [],
    code: res.code,
    message: res.message,
  };
  result.data.saleOrderItemList = await getSoItem(params);
  // if(result.data.invoiceStatus !=='2') result.data = null
  return result;  
};
/**
 * 新增采购计划拉去备货计划数据管理信息
 */
// export const pullPlanPurchaseRequest = (virtualInventory) => {
//   return http({
//     method: "get",
//     url: process.env.VUE_APP_SALEPREDICT_URL + `salepredict/v1/readyPlanResults/${virtualInventory}`
//   });
// };
export const pullPlanPurchaseRequest = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `plan/v1/queryAllReady`,
    data
  });
};

/**
 * 修改采购计划管理信息
 */
export const updatePurchaseRequest = (data) => {
  return http({
    method: "put",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_SCM_URL + `purchaserequest/v1/purchaserequests/purchaseRequestAndItems`,
    data
  });
};

/**
 * 新增采购计划管理信息
 */
export const addPurchaseRequest = (data) => {
  return http({
    method: "post",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_SCM_URL + `purchaserequest/v1/purchaserequests/purchaseRequestAndItems`,
    data
  });
};

/**
 * 查询建议采购数量
 */
export const searchSuggestQuantity = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `plan/v1/queryReady`,
    data
  });
};
/**
 * 采购申请新增也调用：批量查询物料
 */
export const batchQuery = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_MATERIAL_URL + `/material/v1/materials/querytMaterialAndFinishedProductFetch`,
    data
  });
};

/**
 * 采购申请新增也调用：批量查询规格
 */
export const confirmationNoQuery = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `scm/v1/factoryconfirmitems/searchConfirmQuantityPerPackageByCodeFetch`,
    data
  });
};
