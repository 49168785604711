<template>
  <div class="purchaseRequisition container">
    <div v-if="!isShowEdit" class="part">
      <div class="actions_part clearfix">
        <div class="actions_wrap">
          <div class="display_ib mr10 mb10">
            <span class="tag">请求编号：</span>
            <el-input v-model="purchaseRequestCode" class="input_single w200 mr10" placeholder="请输入" @keyup.enter.native="searchHandle"/>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">销售订单编号：</span>
            <el-input v-model="saleOrderId" class="input_single w200 mr10" placeholder="请输入" @keyup.enter.native="searchHandle"/>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">产品编号：</span>
            <el-input v-model="productCode" class="input_single w200 mr10" placeholder="请输入" @keyup.enter.native="searchHandle"/>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">物料编号：</span>
            <el-input v-model="materialCode" class="input_single w200 mr10" placeholder="请输入" @keyup.enter.native="searchHandle"/>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">请求类型：</span>
            <el-select
              v-model="purchaseType"
              class="select_single w200 mr10"
              placeholder="请选择"
              @change="searchHandle">
              <el-option key="" label="全部" value=""/>
              <el-option
                v-for="item in requestTypeList"
                :key="item.dictItemValue"
                :label="item.dictItemName"
                :value="item.dictItemValue"
              />
            </el-select>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">创建日期：</span>
            <el-date-picker
              v-model="dateVal"
              unlink-panels
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="dateValChange"
            />
          </div>
        </div>
        <div class="actions_btn_wrap down t_right">
          <!-- <el-button type="primary" :loading="downloadExcelLoading" @click="downloadSelectExcelHandle">导出已选择项</el-button> -->
          <el-button type="primary" @click="excelDownload">导出Excel</el-button>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            circle
            title="搜索"
            @click="searchHandle"
          />
          <el-button
            size="small"
            type="primary"
            icon="el-icon-plus"
            circle
            title="添加"
            @click="addHandle"
          />
        </div>
      </div>

      <div class="table_part clearfix">
        <el-tabs v-model="activeName" class="subnav_wrap hidden" @tab-click="switchTabHandle">
          <el-tab-pane
            v-loading="tableLoading"
            v-for="item in subNavList"
            :key="item.value"
            :label="item.label"
            :name="item.value">

            <!-- <Table
              :tableList="requestList"
              :parentData="parentData"
              :childData="childData"
              :tableFieldsList="fieldsList"
              :tableParentFieldsList="parentFieldsList"
              :tableLoading="tableLoading"
              @editHandle="editHandle"
              @submitHandle="submitHandle"
              @deleteHandle="deleteHandle">
            </Table> -->

            <!-- <el-table
              class="table_list"
              :ref="'multipleTable' + activeName"
              :data="requestList"
              border
              fit
              stripe
              @expand-change="expandChange"
              @select="selectHandle"
              @select-all="selectAllHandle"> -->
            <el-table
              :ref="'multipleTable' + activeName"
              :data="requestList"
              :expand-row-keys="expands"
              class="table_list"
              row-key="id"
              border
              fit
              stripe
              @row-click="rowClick"
              @expand-change="rowClick">
              <!-- <el-table-column type="selection" /> -->
              <el-table-column type="expand">

                <template slot-scope="scope">
                  <div class="actions_wrap mb20 clearfix">
                    <el-button class="fr" type="primary" @click="childExcelDownload(scope.row)">导出Excel</el-button>
                    <el-button v-if="scope.row.prStatus === 'Draft' || scope.row.prStatus === 'Rejected'" class="fr mr10" type="primary" @click="editHandle(scope.$index, scope.row)">更新导入Excel</el-button>
                  </div>
                  <!-- <el-table
                      class="table_list"
                      :ref="'childMultipleTable_' + scope.row.id"
                      :data="scope.row.priList"
                      border
                      fit> -->
                  <pl-table
                    :ref="'childMultipleTable_' + scope.row.id"
                    :data="scope.row.priList"
                    :max-height="700"
                    :use-virtual="true"
                    :row-height="90"
                    class="table_list"
                    style="width: 100%;height:100%;"
                    border
                    fit>
                    <!-- <el-table-column fixed type="selection" /> -->
                    <el-table-column fixed label="行号" min-width="100">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.sn }}</p>
                      </template>
                    </el-table-column>

                    <el-table-column :show-overflow-tooltip="true" fixed label="物料编号" min-width="180" class-name="marpno_wuliao" >
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.materialCode }}</p>
                      </template>
                    </el-table-column>

                    <el-table-column label="产品编号" min-width="180" class-name="marpno_wuliao" >
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}" :title="row.productCode" class="ellipsis">{{ row.productCode }}</p>
                        <!-- <p :title="row.productCode">{{ row.productCode }}</p> -->
                      </template>
                    </el-table-column>
                    <pl-table-column label="期望交期" min-width="180" class-name="marpno_wuliao">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.requiredDate }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column v-if="activeName == 0 || activeName == 'Approved'" label="工厂交期" min-width="180" class-name="marpno_wuliao">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.offeredDate }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column label="最晚调拨时间" min-width="180" class-name="marpno_wuliao">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.latestTransferDate }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column label="请求数量" min-width="180" class-name="marpno_wuliao">
                      <template slot-scope="{ row }">
                        <span v-if="row.purchaseMeasurementUnit == '米'">
                          <p :class="{isThrough:row.cancelStatus}">{{ (row.requiredPackages ? row.requiredPackages : 0) + '包 ' + (row.requiredQuantityPerPackage ? row.requiredQuantityPerPackage : 0) + '米/包 ' + (row.requiredQuantity ? row.requiredQuantity : 0) + '米' }}</p>
                        </span>
                        <span v-else>
                          <p :class="{isThrough:row.cancelStatus}">{{ (row.requiredQuantity ? row.requiredQuantity : 0) + (row.purchaseMeasurementUnit ? row.purchaseMeasurementUnit : '条') }}</p>
                        </span>
                      </template>
                    </pl-table-column>
                    <pl-table-column v-if="activeName == 0 || activeName == 'Approved'" label="采购数量" min-width="180" class-name="marpno_wuliao">
                      <template slot-scope="{ row }">
                        <span v-if="row.purchaseMeasurementUnit == '米'">
                          <p :class="{isThrough:row.cancelStatus}">{{ (row.offeredPackages ? row.offeredPackages : 0) + '包 ' + (row.offeredQuantityPerPackage ? row.offeredQuantityPerPackage : 0) + '米/包 ' + (row.offeredQuantity ? row.offeredQuantity : 0) + '米' }}</p>
                        </span>
                        <span v-else>
                          <p :class="{isThrough:row.cancelStatus}">{{ (row.offeredQuantity ? row.offeredQuantity : 0) + (row.purchaseMeasurementUnit ? row.purchaseMeasurementUnit : '条') }}</p>
                        </span>
                      </template>
                    </pl-table-column>
                    <pl-table-column label="库位" min-width="180" class-name="marpno_wuliao">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.subInventoryName }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column label="销售订单编号" min-width="180" class-name="marpno_wuliao">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.saleOrderId }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column label="采购订单编号" min-width="180" class-name="marpno_wuliao">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.purchaseOrderId }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column label="确认书编号" min-width="180" class-name="marpno_wuliao">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.confirmNumbers }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column label="特殊要求" min-width="180" class-name="marpno_wuliao">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.specialRequire }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column label="货妥数量" min-width="100" class-name="marpno_wuliao">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.cargoQuantity }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column v-if="scope.row.prStatus === 'Approved'" label="状态" min-width="100" >
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.cancelStatus === true ? '已取消' : '正常' }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column label="备注" min-width="180">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.remark }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column label="原确认书编号" min-width="180">
                      <template slot-scope="{ row }">
                        <p :class="{isThrough:row.cancelStatus}">{{ row.originalConfirmNum }}</p>
                      </template>
                    </pl-table-column>
                    <pl-table-column v-if="scope.row.prStatus === 'Approved'" label="操作" fixed="right" min-width="180">
                      <template slot-scope="{ row, $index }">
                        <el-button :disabled="row.purchaseOrderId || row.cancelStatus === true" class="el-icon-document-delete" type="danger" circle size="mini" title="撤销" @click.stop="revokeHandle($index, row)"/>
                        <!-- <el-button class="el-icon-document-delete" type="danger" circle size="mini" :title="row.purchaseOrderId ?  '不可取消' : (row.cancelStatus === true ? '已取消' : '取消')" :disabled="row.purchaseOrderId || row.cancelStatus === true" @click.stop="revokeHandle($index, row)"></el-button> -->
                      </template>
                    </pl-table-column>
                    <pl-table-column v-if="scope.row.prStatus==='Draft'" label="操作" fixed="right" min-width="180">
                      <template slot-scope="{ row, $index }">
                        <el-button class="el-icon-delete" type="danger" circle size="mini" title="删除" @click.stop="editDeleteHandle($index, row)"/>
                      </template>
                    </pl-table-column>
                  </pl-table>
                </template>
              </el-table-column>
              <el-table-column label="请求编号">
                <template slot-scope="{ row }">
                  {{ row.purchaseRequestCode }}
                </template>
              </el-table-column>
              <el-table-column label="请求类型">
                <template slot-scope="{ row }">
                  {{ row.purchaseTypeName }}
                </template>
              </el-table-column>
              <el-table-column label="入库仓位">
                <template slot-scope="{ row }">
                  {{ row.virtualInventoryName }}
                </template>
              </el-table-column>
              <el-table-column label="创建时间">
                <template slot-scope="{ row }">
                  {{ row.createTime }}
                </template>
              </el-table-column>
              <el-table-column label="申请人">
                <template slot-scope="{ row }">
                  {{ row.applicantName }}
                </template>
              </el-table-column>
              <el-table-column label="凭证状态">
                <template slot-scope="{ row, $index }">
                  <el-popover
                    placement="right"
                    width="300"
                    trigger="hover"
                    open-delay="1000"
                    @show="checkApprovalProcess(row, $index)">
                    <div>
                      <i class="block">
                        审批人：
                        <em>{{ row.fullName }}</em>
                      </i>
                      <i class="block mt10 mb10">
                        审批时间：
                        <em>{{ row.approvalTime }}</em>
                      </i>
                      <i class="block">
                        审批意见：
                        <em>{{ row.content }}</em>
                      </i>
                    </div>
                    <el-tag slot="reference" :type="row.prStatus | prStatusFilter" class="pointer">{{ row.prStatusName }}</el-tag>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="{ row, $index }">
                  <div v-if="row.prStatus === 'Draft' || row.prStatus === 'Rejected'">
                    <el-button class="el-icon-edit" type="primary" circle size="mini" title="编辑" @click.stop="editHandle($index, row)"/>
                    <el-button class="el-icon-check" type="success" circle size="mini" title="提交审批" @click.stop="submitHandle($index, row)"/>
                    <el-button class="el-icon-delete" type="danger" circle size="mini" title="删除" @click.stop="deleteHandle($index, row)"/>
                  </div>
                  <div v-if="row.prStatus === 'Approved'">
                    <el-button class="el-icon-switch-button" type="danger" circle size="mini" title="关闭" @click.stop="closeHandle($index, row)"/>
                    <el-button v-if="row.purchaseTypeName === '采购申请差异单'" class="el-icon-s-promotion" type="warning" circle size="mini" title="推送" @click.stop="pushHandle($index, row)"/>
                  </div>
                  <div v-if="row.prStatus === 'Close'">
                    <el-button class="el-icon-switch-button" type="danger" disabled circle size="mini" title="关闭"/>
                  </div>
                  <div v-if="row.prStatus === 'Terminated'">
                    <el-button class="el-icon-delete" type="danger" circle size="mini" title="删除" @click.stop="deleteHandle($index, row)"/>
                  </div>
                </template>
              </el-table-column>
            </el-table>

          </el-tab-pane>
        </el-tabs>

        <div class="pagination_wrap fr">
          <el-pagination
            :current-page.sync="currentPage"
            :page-sizes="[10, 50]"
            :page-size="pageSize"
            :total="total"
            layout="sizes, total, prev, pager, next"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>

    </div>

    <!-- 编辑 -->
    <div v-loading="editPartLoading" v-else class="edit_part">
      <div class="clearfix mb10">
        <div class="display_ib mr10 mb10">
          <span class="tag"><span class="red mr5">*</span>请求类型：</span>
          <el-select
            :disabled="!isEmptyData"
            v-model="editData.purchaseType"
            class="select_single w200 mr10"
            placeholder="请选择"
            @change="purchaseTypeSelect">
            <el-option key="" label="请选择" value=""/>
            <el-option
              v-for="item in requestTypeList"
              :key="item.dictItemValue"
              :label="item.dictItemName"
              :value="item.dictItemValue"/>
          </el-select>
        </div>
        <div class="display_ib mr10 mb10">
          <span class="tag"><span class="red mr5">*</span>入库仓位：</span>
          <el-select
            v-if="isDisabledInventoryPull==true"
            :disabled="isDisabledInventoryPull"
            v-model="editData.virtualInventoryId"
            class="select_single w200 mr10"
            placeholder="请选择"
            @change="virtualInventorySelect">
            <el-option key="" label="请选择dis" value=""/>
            <el-option
              v-for="item in virtualInventoryList"
              :key="item.index"
              :label="item.name"
              :value="item.index"/>
          </el-select>
          <el-select
            v-else
            :disabled="!isEmptyData"
            v-model="editData.virtualInventoryId"
            class="select_single w200 mr10"
            placeholder="请选择"
            @change="virtualInventorySelect">
            <el-option key="" label="请选择" value=""/>
            <el-option
              v-for="item in virtualInventoryList"
              :key="item.index"
              :label="item.name"
              :value="item.index"/>
          </el-select>
        </div>
        <div v-if="isPurchaseBySale" class="display_ib mr10 mb10">
          <span class="tag">销售订单编号：</span>
          <el-input :disabled="!isEmptyData" v-model.trim="editData.saleOrderId" class="input_single w200 mr10" placeholder="请输入"/>
        </div>
        <!-- <div class="display_ib mr10 mb10" v-if="isDifferenceOrder">
          <span class="tag">原PR编号：</span>
          <el-input class="input_single w200 mr10" :disabled="!isEmptyData" v-model.trim="editData.saleOrderId" placeholder="请输入"></el-input>
        </div> -->
        <el-button
          :disabled="!isEmptyData || isDisabledPull"
          class="mr10 mb10"
          size="small"
          type="primary"
          icon="el-icon-refresh-right"
          circle
          title="拉取数据"
          @click="pullDataHandle"
        />
        <!-- <div class="display_ib mr10 mb10">
          <span class="tag">供应商：</span>
          <el-select
            class="select_single w200 mr10"
            v-model="editData.supplierId"
            filterable
            :disabled="isDisabledSupplier"
            @change="supplierNameChange"
            placeholder="不限">
            <el-option key="" label="不限" value=""></el-option>
            <el-option
              v-for="item in supplierList"
              :key="item.supplierId"
              :label="item.supplierCode"
              :value="item.supplierId">
            </el-option>
          </el-select>
        </div> -->
        <div v-if="isPurchaseBySale" class="display_ib mr10">
          <span class="tag">客户名称：</span>
          <span class="tag">{{ editData.customerName }}</span>
        </div>

        <a v-if="!isDisabledInventoryPull" class="link inline_block lh36 fr ml10" type="primary" @click="exportExcelTemplate">采购申请导入模板</a>
        <el-button
          v-if="!isDisabledInventoryPull"
          class="fr"
          size="small"
          type="primary"
          icon="el-icon-plus"
          circle
          title="添加"
          @click="addEditHandle"
        />
      </div>

      <upload-excel-component v-if="!isDisabledInventoryPull" :on-success="importExcelSuccess" :before-upload="importExcelBeforeUpload" class="mb20" />
      <!-- <el-table :data="tableData" border highlight-current-row style="width: 100%;margin-top:20px;">
        <el-table-column v-for="item of tableHeader" :key="item" :prop="item" :label="item" />
      </el-table> -->

      <div class="hidden">
        <!-- 编辑组件 -->
        <editTable
          ref="editTable"
          :table-list="editData.pricList"
          :total="editData.pricList && editData.pricList.length ? editData.pricList.length : 0"
          :table-leader-fields-list="leaderFieldsList"
          :table-tail-fields-list="tailFieldsList"
          :table-fields-list="editFieldsList"
          :select-remote-loading="selectRemoteLoading"
          @deleteHandle="deleteEditItemHandle"
          @inputChange="inputChange"
          @inputBlur="inputBlur"
          @selectChange="selectChange"
          @selectVisibleChange="selectVisibleChange"
          @unitNumberInputChange="unitNumberInputChange"
          @selectRemoteMethod="selectRemoteMethod"
          @selectRemoteChange="selectRemoteChange"
          @selectRemoteClick="selectRemoteClick"/>
        <!-- <editTable
          :type="'selection'"
          :tableList="editData.pricList"
          :tableLeaderFieldsList="leaderFieldsList"
          :tableTailFieldsList="tailFieldsList"
          :tableFieldsList="editFieldsList"
          :selectRemoteLoading="selectRemoteLoading"
          @deleteHandle="deleteEditItemHandle"
          @inputChange="inputChange"
          @inputBlur="inputBlur"
          @selectChange="selectChange"
          @selectVisibleChange="selectVisibleChange"
          @unitNumberInputChange="unitNumberInputChange"
          @selectRemoteMethod="selectRemoteMethod"
          @selectRemoteChange="selectRemoteChange"
          @selectRemoteClick="selectRemoteClick"
          @selectionChange="editSelectionChange">
        </editTable> -->

        <div class="edit_btn_wrap mt20 fr">
          <el-button @click.stop="cancelEditHandle">取消</el-button>
          <el-button v-preventReClick type="primary" @click.stop="updateEditHandle('save')">保存</el-button>
          <el-button v-preventReClick type="primary" @click.stop="updateEditHandle('submit')">提交审批</el-button>
        </div>
      </div>

    </div>

    <!-- 对话框 -->
    <Dialog v-if="dialogVisible" :dialogDesc="dialogDesc" :dialogVisible="dialogVisible" @dialogConfirmHandle="dialogConfirmHandle" @dialogCancelHandle="dialogCancelHandle"/>

  </div>
</template>

<script>
import {
  getMeasurementUnitCache,
  existMaterialCodeCache,
  getBatchQueryCache,
  subInventoryListCache,
  constDictListCache,
  dictListCache,
  supplierListCache
} from '@/api/cacheApi.js';
import {
  getUUID,
  getBatchUUID,
  dictList,
  constDictList,
  supplierDictList,
  subInventoryTypeList,
  getProductCodeByMC,
  getSupplierByMC,
  getMeasurementUnitByMC,
  existMaterialCode,
  approvalProcessInfo,
  getBatchQuery
} from '@/api/publicApi.js';
import {
  purchaseRequestList,
  purchaseRequestDraftList,
  submitPurchaseRequest,
  deletePurchaseRequest,
  deletePurchaseRequestitems,
  searchPurchaseRequest,
  pullPurchaseRequest,
  pullPlanPurchaseRequest,
  updatePurchaseRequest,
  addPurchaseRequest,
  searchSuggestQuantity,
  batchQuery,
  confirmationNoQuery,
  closeTransferOrder,
  revokePurchaseRequestItem,
  pushPurchaseRequestitem
} from '@/api/module/purchaseRequestApi.js';
import {
  getNowDate,
  timeFormat,
  isCheckDate,
  oaDateToDateTime,
  excelDateFormatToDate,
  verificationQuantity,
  getTimeRange,
  exportExcel,
  formatDateTime
} from '@/utils/index.js';
import { statusTypeHandle } from '@/utils/public.js';
import { getNowFormatDate } from '@/utils/index.js';
import UploadExcelComponent from '@/components/UploadExcel/index.vue';
import Table from '@/layouts/components/common/Table.vue';
import Dialog from '@/layouts/components/common/Dialog.vue';
import editTable from '@/layouts/components/common/EditTablePagination.vue';
// import editTable from '@/layouts/components/common/EditTableListumy.vue';
export default {
  name: 'PurchaseRequisition',
  components: {
    UploadExcelComponent,
    Table,
    Dialog,
    editTable
  },
  filters: {
    prStatusFilter(status) {
      const statusMap = {
        Draft: '',
        Approving: 'warning',
        Approved: 'success',
        Rejected: 'danger',
        Terminated: 'info',
        Close: 'danger'
      };
      return statusMap[status];
    }
  },
  data() {
    const self = this;
    return {
      // Tab列表
      subNavList: [
        {
          label: '全部采购申请',
          value: ''
        },
        {
          label: '草稿',
          value: 'Draft'
        },
        {
          label: '审批中',
          value: 'Approving'
        },
        {
          label: '已通过',
          value: 'Approved'
        },
        {
          label: '已驳回',
          value: 'Rejected'
        }
        // {
        //   label: '已终止',
        //   value: 'Terminated'
        // }
      ],

      // 采购申请列表
      beginTime: '',
      endTime: '',
      purchaseRequestCode: '',
      productCode: '',
      materialCode: '',
      purchaseType: '',
      sort: 'desc',
      dateVal: '', // 创建时间
      total: 0,
      currentPage: 1,
      pageSize: 10,
      requestList: [],
      isEdit: false,
      tableSearch: '', // Table关键字搜索
      activeName: '',
      saleOrderId: '',

      // NEW TBALE组件列表
      multipleSelection: [],
      childMultipleSelection: [],
      selectUuidList: [],

      requestTypeList: [], // 请求类型列表
      virtualInventoryList: [], // 入库仓位列表
      subInventoryList: [], // 库位列表
      supplierList: [], // 供应商列表
      expands: [],

      // 对话框
      dialogVisible: false, // 对话框是否可见
      dialogDesc: '',
      submitId: '',
      deleteId: '',
      deleteItemId: '',
      revokeId: '',
      closedId: '',
      orderStatus: '',

      // 编辑
      editType: '',
      editPartLoading: false,
      isShowEdit: false, // 是否显示编辑页
      isEmptyData: false, // 是否可编辑
      isDisabledPull: false, // 是否支持拉取
      isDisabledSupplier: false, // 是否支持供应商选择
      isPurchaseBySale: false, // 是否是以销定采类型
      isDifferenceOrder: false, // 是否是差异单
      // isDisabledAddEdit: false, // 新增子项按钮是否可用
      selectRemoteLoading: false,
      isSaveDisabled: false,
      isSubmitDisabled: false,
      isDisabledInventoryPull: false, // 父项入库仓位是否可编辑

      submitId: '',
      submitObj: {},
      deleteId: '',
      submitObj: {},
      revokeUuid: '',
      pushId: '',
      pushUserName: '',

      // Table组件
      tableLoading: true,
      fieldsList: [], // Table子列表表头
      lessFieldsList: [
        {
          name: '物料编号',
          class: 'w8p'
        },
        {
          name: '产品编号',
          class: 'w8p'
        },
        {
          name: '期望交期',
          class: 'w8p'
        },
        {
          name: '请求数量',
          class: 'w9p'
        },
        {
          name: '库位',
          class: 'w8p'
        },
        {
          name: '采购订单编号',
          class: 'w8p'
        },
        {
          name: '确认书编号',
          class: 'w8p'
        },
        {
          name: '特殊要求',
          class: 'w5p'
        },
        {
          name: '备注',
          class: 'w5p'
        }
      ],
      moreFieldsList: [
        {
          name: '物料编号',
          class: 'w8p'
        },
        {
          name: '产品编号',
          class: 'w8p'
        },
        {
          name: '期望交期',
          class: 'w8p'
        },
        {
          name: '工厂交期',
          class: 'w8p'
        },
        {
          name: '最晚调拨时间',
          class: 'w8p'
        },
        {
          name: '请求数量',
          class: 'w9p'
        },
        {
          name: '采购数量',
          class: 'w9p'
        },
        {
          name: '库位',
          class: 'w8p'
        },
        {
          name: '采购订单编号',
          class: 'w8p'
        },
        {
          name: '确认书编号',
          class: 'w8p'
        },
        {
          name: '货妥数量',
          class: 'w8p'
        },
        {
          name: '特殊要求',
          class: 'w5p'
        },
        {
          name: '备注',
          class: 'w5p'
        }
      ],
      parentFieldsList: [ // Table父列表表头
        {
          name: '请求编号',
          field: 'purchaseRequestCode',
          class: 'w8p',
          width: '200'
        },
        {
          name: '请求类型',
          field: 'purchaseTypeName',
          class: 'w8p',
          width: '150'
        },
        {
          name: '入库仓位',
          field: 'virtualInventoryName',
          class: 'w8p',
          width: '100'
        },
        {
          name: '创建时间',
          field: 'createTime',
          class: 'w8p',
          width: '200'
        },
        {
          name: '申请人',
          field: 'applicantName',
          class: 'w8p',
          width: '100'
        },
        {
          name: '凭证状态',
          field: 'virtualInventoryName',
          class: 'w8p'
          // width: '100'
        }
      ],
      // parentFieldsList: [  // Table父列表表头
      //   {
      //     name: '创建时间',
      //     class: 'w8p'
      //   },
      //   {
      //     name: '申请人',
      //     class: 'w4p'
      //   },
      //   {
      //     name: '请求编号',
      //     class: 'w12p'
      //   },
      //   {
      //     name: '请求类型',
      //     class: 'w8p'
      //   },
      //   {
      //     name: '入库仓位',
      //     class: 'w5p'
      //   },
      //   {
      //     name: '审批',
      //     class: ''
      //   }
      // ],
      // parentData: {
      //   status: '',
      //   fieldsList: [
      //     {
      //       name: '创建时间',
      //       field: 'createTime',
      //       class: 'w8p',
      //       width: '200',
      //       // isShowTit: true
      //     },
      //     {
      //       name: '申请人',
      //       field: 'applicantName',
      //       class: 'w4p',
      //       width: '100',
      //       // isShowTit: true
      //     },
      //     {
      //       name: '请求编号',
      //       field: 'purchaseRequestCode',
      //       class: 'w12p',
      //       width: '200',
      //       // isShowTit: true
      //     },
      //     {
      //       name: '请求类型',
      //       field: 'purchaseTypeName',
      //       class: 'w8p',
      //       width: '150',
      //       // isShowTit: true
      //     },
      //     {
      //       name: '入库仓位',
      //       field: 'virtualInventoryName',
      //       class: 'w5p',
      //       width: '100',
      //       // isShowTit: true
      //     },
      //     {
      //       name: '审批',
      //       field: '',
      //       class: '',
      //       width: '100',
      //       // isShowTit: false,
      //       // isPopover: true
      //     },
      //     {
      //       name: '操作',
      //       field: '',
      //       class: 'fr mr15',
      //       // isShowTit: false,
      //       // isUpDown: true
      //     },
      //     // {
      //     //   name: '',
      //     //   field: '',
      //     //   class: 'fr',
      //     //   isShowTit: false,
      //     //   isAction: true
      //     // }
      //   ]
      // },
      parentData: {
        status: '',
        data: [
          {
            name: '创建时间：',
            field: 'createTime',
            class: 'w8p',
            isShowTit: true
          },
          {
            name: '申请人',
            field: 'applicantName',
            class: 'w4p',
            isShowTit: true
          },
          {
            name: '请求编号',
            field: 'purchaseRequestCode',
            class: 'w12p',
            isShowTit: true
          },
          {
            name: '请求类型',
            field: 'purchaseTypeName',
            class: 'w8p',
            isShowTit: true
          },
          {
            name: '入库仓位',
            field: 'virtualInventoryName',
            class: 'w5p',
            isShowTit: true
          },
          // {
          //   name: '客户名称',
          //   field: 'customerName',
          //   class: 'w8p',
          //   isShowTit: true,
          //   isFilterField: true
          // },
          // {
          //   name: '仓位',
          //   field: 'inventoryName',
          //   class: 'w6p',
          //   isShowTit: true
          // },
          {
            name: '审批',
            field: '',
            class: '',
            isShowTit: false,
            isPopover: true
          },
          {
            name: '',
            field: '',
            class: 'fr mr15',
            isShowTit: false,
            isUpDown: true
          },
          {
            name: '',
            field: '',
            class: 'fr',
            isShowTit: false,
            isAction: true
          }
        ]
      },
      childData: {},
      lessChildData: {
        name: 'priList',
        data: [
          {
            name: '',
            field: 'materialCode',
            class: 'w8p'
          },
          {
            name: '',
            field: 'productCode',
            class: 'w8p'
          },
          {
            name: '',
            field: 'requiredDate',
            class: 'w8p'
          },
          {
            name: '',
            field_1: 'requiredPackages',
            field_2: 'requiredQuantityPerPackage',
            field_3: 'requiredQuantity',
            field: 'requiredQuantity',
            field_unit: 'purchaseMeasurementUnit',
            class: 'w9p',
            isQuantityField: true
          },
          {
            name: '',
            field: 'subInventoryName',
            class: 'w8p'
          },
          {
            name: '',
            field: 'purchaseOrderId',
            class: 'w8p'
          },
          {
            name: '',
            field: 'confirmNumbers',
            class: 'w8p'
          },
          {
            name: '',
            field: 'specialRequire',
            class: 'w5p'
          },
          {
            name: '',
            field: 'remark',
            class: 'w5p'
          }
          // {
          //   name: '',
          //   field: '',
          //   class: 'w10p fr',
          //   isAction: true
          // }
        ]
      },
      moreChildData: {
        name: 'priList',
        data: [
          {
            name: '',
            field: 'materialCode',
            class: 'w8p'
          },
          {
            name: '',
            field: 'productCode',
            class: 'w8p'
          },
          {
            name: '',
            field: 'requiredDate',
            class: 'w8p'
          },
          {
            name: '',
            field: 'offeredDate',
            class: 'w8p'
          },
          {
            name: '',
            field: 'latestTransferDate',
            class: 'w8p'
          },
          {
            name: '',
            field_1: 'requiredPackages',
            field_2: 'requiredQuantityPerPackage',
            field_3: 'requiredQuantity',
            field: 'requiredQuantity',
            field_unit: 'purchaseMeasurementUnit',
            class: 'w9p',
            isQuantityField: true
          },
          {
            name: '',
            field_1: 'offeredPackages',
            field_2: 'offeredQuantityPerPackage',
            field_3: 'offeredQuantity',
            field: 'offeredQuantity',
            field_unit: 'purchaseMeasurementUnit',
            class: 'w9p',
            isQuantityField: true
          },
          {
            name: '',
            field: 'subInventoryName',
            class: 'w8p'
          },
          {
            name: '',
            field: 'purchaseOrderId',
            class: 'w8p'
          },
          {
            name: '',
            field: 'confirmNumbers',
            class: 'w8p'
          },
          {
            name: '',
            field: '',
            class: 'w8p'
          },
          {
            name: '',
            field: 'specialRequire',
            class: 'w5p'
          },
          {
            name: '',
            field: 'remark',
            class: 'w5p'
          }
          // {
          //   name: '',
          //   field: '',
          //   class: 'w10p fr',
          //   isAction: true
          // }
        ]
      },

      // 编辑数据
      editData: {
        pricList: []
      },
      editItemData: {
        uuid: '',
        materialCode: '',
        productCode: '',
        supplierId: '',
        supplierCode: '',
        supplierName: '',
        requiredDate: '',
        requiredPackages: '',
        requiredQuantityPerPackage: '',
        requiredQuantity: '',
        purchaseMeasurementUnit: '米',
        suggestQuantity: '',
        subInventory: '',
        subInventoryName: '',
        specialRequire: '',
        saleOrderId: '',
        remark: '',
        originalSn: '',
        originalPurchaseRequestCode: '',
        originalConfirmNum: ''
      },

      // 编辑组件数据
      editFieldsList: [],
      standardEditFieldsList: [
        {
          name: '产品编号',
          field: 'productCode',
          class: 'w16p',
          width: '180',
          isRules: true
        },
        {
          name: '供应商',
          field: 'supplierName',
          field: 'supplierShortName',
          class: 'w16p',
          width: '150'
        },
        {
          name: '期望交期',
          field: 'requiredDate',
          class: 'w18p',
          width: '180',
          isRules: true,
          editrender: true
        },
        {
          name: '请求数量',
          field_1: 'requiredPackages',
          field_2: 'requiredQuantityPerPackage',
          field_3: 'requiredQuantity',
          field: 'requiredQuantity',
          field_unit: 'purchaseMeasurementUnit',
          field_name: 'requiredPackagesumy',
          class: 'w13p',
          width: '400',
          isRules: true,
          editrender: true,
          fieldsname: true

        },
        {
          name: '建议采购数量（按周）',
          field: 'suggestQuantity',
          field_unit: 'purchaseMeasurementUnit',
          class: 'w13p',
          width: '180',
          isQuantityField: true
        },
        {
          name: '库位',
          field: 'subInventory',
          field_name: 'subInventoryName',
          class: 'w16p',
          width: '150',
          isRules: true,
          editrender: true,
          fieldsname: true
        },
        {
          name: '销售订单编号',
          field: 'saleOrderId',
          class: 'w16p',
          width: '200',
          isRules: true
        },
        {
          name: '特殊要求',
          field: 'specialRequire',
          class: 'w13p',
          width: '150',
          editrender: true
        },
        {
          name: '备注',
          field: 'remark',
          class: 'w16p',
          width: '260',
          editrender: true
        }
      ],
      originalEditFieldsList: [
        {
          name: '产品编号',
          field: 'productCode',
          class: 'w16p',
          width: '150',
          isRules: true
        },
        {
          name: '供应商',
          // field: 'supplierName',
          field: 'supplierShortName',
          class: 'w16p',
          width: '150'
        },
        {
          name: '* 期望交期',
          field: 'requiredDate',
          class: 'w18p',
          width: '180',
          isRules: true
        },
        {
          name: '* 请求数量',
          field_1: 'requiredPackages',
          field_2: 'requiredQuantityPerPackage',
          field_3: 'requiredQuantity',
          field: 'requiredQuantity',
          field_unit: 'purchaseMeasurementUnit',
          class: 'w13p',
          width: '400',
          isRules: true
        },
        {
          name: '建议采购数量（按周）',
          field: 'suggestQuantity',
          field_unit: 'purchaseMeasurementUnit',
          class: 'w13p',
          width: '180',
          isQuantityField: true
        },
        {
          name: '* 库位',
          field: 'subInventory',
          class: 'w16p',
          width: '150',
          isRules: true
        },
        {
          name: '销售订单编号',
          field: 'saleOrderId',
          class: 'w16p',
          width: '200',
          isRules: true
        },
        {
          name: '特殊要求',
          field: 'specialRequire',
          class: 'w13p',
          width: '150'
        },
        {
          name: '备注',
          field: 'remark',
          class: 'w16p',
          width: '260'
        },
        {
          name: '原PR行号',
          field: 'originalSn',
          class: 'w16p',
          width: '150'
        },
        {
          name: '原PR编号',
          field: 'originalPurchaseRequestCode',
          class: 'w16p',
          width: '150'
        },
        {
          name: '原确认书编号',
          field: 'originalConfirmNum',
          class: 'w16p',
          width: '150'
        }
      ],
      leaderFieldsList: [
        {
          name: '行号',
          field: 'sn',
          class: 'w10p',
          width: '50'
        },
        {
          name: '* 物料编号',
          field: 'materialCode',
          class: 'w10p',
          width: '200',
          isRules: true,
          editrender: true
        }
      ],
      tailFieldsList: [
        {
          name: '操作',
          field: '',
          class: '',
          width: '100',
          isAction: true,
          hasDelete: true
        }
      ],
      // 编辑字段呈现方式集合（默认添加模式）
      editModeSet: {},
      textSet: {
        sn: true,
        productCode: true,
        supplierName: true,
        supplierShortName: true,
        suggestQuantity: true,
        originalSn: true,
        originalPurchaseRequestCode: true,
        originalConfirmNum: true
      },
      inputSet: {
        materialCode: {
          class: ''
        },
        // productCode: true,
        saleOrderId: true,
        specialRequire: true,
        remark: true,
        inputData: {
          maxlength: '',
          placeholder: ''
        }
      },
      selectSet: {
        subInventory: {
          selectListName: 'subInventoryList',
          value: 'subInventory',
          label: 'subInventoryName',
          disabled: ''
        }
      },
      selectRemoteSet: {
        // productCode: {
        //   selectListName: 'productCodeList',
        //   value: 'productCode',
        //   label: 'productCode'
        // }
      },
      numberInputSet: {},
      datePickerSet: {
        requiredDate: {
          disabled: ''
        }
      },
      unitSet: {
        requiredPackages: true
        // requiredQuantityPerPackage:{
        //   disabled:''
        // }
        // requiredQuantityPerPackage: {
        //   disabled: true
        // }
      },
      latestTime: '',
      tableData: [],
      tableHeader: [],

      childHeaderList: ['请求编号', '行号', '物料编号', '产品编号', '期望交期', '工厂交期', '最晚调拨时间', '请求数量', '请求数量规格', '采购数量', '库位', '采购订单编号', '确认书编号', '销售订单编号', '特殊要求', '货妥数量', '备注'],
      childFieldsList: ['purchaseRequestCode', 'sn', 'materialCode', 'productCode', 'requiredDate', 'offeredDate', 'latestTransferDate', 'requiredQuantity', 'requiredQuantityPerPackage', 'offeredQuantity', 'subInventoryName', 'purchaseOrderId', 'confirmNumbers', 'saleOrderId', 'specialRequire', 'cargoQuantity', 'remark'],
      approvedChildHeaderList: ['请求编号', '行号', '物料编号', '产品编号', '期望交期', '工厂交期', '最晚调拨时间', '请求数量', '请求数量规格', '采购数量', '库位', '采购订单编号', '确认书编号', '销售订单编号', '特殊要求', '货妥数量', '状态', '备注'],
      approvedChildFieldsList: ['purchaseRequestCode', 'sn', 'materialCode', 'productCode', 'requiredDate', 'offeredDate', 'latestTransferDate', 'requiredQuantity', 'requiredQuantityPerPackage', 'offeredQuantity', 'subInventoryName', 'purchaseOrderId', 'confirmNumbers', 'saleOrderId', 'specialRequire', 'cargoQuantity', 'cancelStatusName', 'remark']

    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    console.log(this.$route.params.tag);
    console.log(this.activeName, this.prStatus);
    this.fieldsList = this.moreFieldsList;
    this.childData = this.moreChildData;
    this.editFieldsList = this.standardEditFieldsList;
    this.getRequestTypeList();
    this.getVirtualInventory();
    this.getSupplierList();
    // this.getPurchaseRequestList();
    this.fun_date();
    // this.addDate();
    if (this.$store.state.PREditShow) {
      // 采购计划携带编辑数据
      // console.log('采购计划生成采购申请数据：', this.$store.state.planToPRList);
      this.editData = {};
      this.editType = 'add';
      this.isEmptyData = true;
      this.isDisabledPull = true;
      this.isDisabledSupplier = true;
      const editDataCopy = JSON.parse(JSON.stringify(this.editData));
      getUUID().then(res => {
        if (res.code === '0') {
          editDataCopy.uuid = res.data;
        }
      });
      editDataCopy.pricList = this.$store.state.planToPRList;
      // 供应商转换
      const supplierData = this.supplierList.find(item => {
        return item.supplierName == editDataCopy.pricList[0].supplierName;
      });
      editDataCopy.supplierName = editDataCopy.pricList.length ? editDataCopy.pricList[0].supplierName : '';
      editDataCopy.supplierId = editDataCopy.pricList.length ? editDataCopy.pricList[0].supplierId : '';
      editDataCopy.supplierCode = supplierData ? supplierData.supplierCode : '';
      for (var i = 0; i < editDataCopy.pricList.length; i++) {
        const data = editDataCopy.pricList[i];
        getUUID().then(res => {
          if (res.code === '0') {
            data.uuid = res.data;
          }
        });
        data.subInventoryList = this.subInventoryList;
        data.suggestQuantity = data.adviceQuantity;
        data.supplierShortName = data.supplierName;
        this.updateEditModeSet('add', data);
      }
      this.editData = editDataCopy;
      this.isShowEdit = true;
      this.$store.commit('getPREditShow');
    }
    if (this.$route.params.tag) {
      console.log('进入');
      this.activeName = 'Draft';
      this.prStatus == 'Draft';
      this.searchHandle();
    }
  },
  methods: {
    load(tree, treeNode, resolve) {
      setTimeout(() => {
        resolve([
          {
            id: 31,
            date: '2016-05-01',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1519 弄'
          }, {
            id: 32,
            date: '2016-05-01',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1519 弄'
          }
        ]);
      }, 1000);
    },
    // 固定展示为两周前的时间范围
    fun_date() {
      this.dateVal = getTimeRange(-90);
      const planDate = getTimeRange(365*50);
      console.log('planDate---', planDate);
      // let planDate=getTimeRange(90);
      this.latestTime = planDate[1];
      this.searchHandle();
    },
    rowClick(row, event, column) {
      // 单击事件
      console.log(row, event, column);
      Array.prototype.remove = function(val) {
        console.log(val);
        const index = this.indexOf(val);
        if (index > -1) {
          this.splice(index, 1);
        }
      };
      if (this.expands.indexOf(row.id) < 0) {
        this.expands.push(row.id);
      } else {
        this.expands.remove(row.id);
      }
    },
    excelDownload() {
      const headers = ['请求编号', '请求类型', '入库仓位', '创建时间', '申请人', '审批'];
      const fields = ['purchaseRequestCode', 'purchaseTypeName', 'virtualInventoryName', 'createTime', 'applicantName', 'prStatusName'];
      exportExcel(headers, fields, this.requestList, '采购申请', true, 'xlsx');
    },
    childExcelDownload(item) {
      let headers = [];
      let fields = [];
      if (item.prStatus === 'Approved') {
        headers = this.approvedChildHeaderList;
        fields = this.approvedChildFieldsList;
      } else {
        headers = this.childHeaderList;
        fields = this.childFieldsList;
      }
      exportExcel(headers, fields, item.priList, item.purchaseRequestCode, true, 'xlsx');
    },
    exportExcelTemplate() {
      // 导出Excel模板
      const headers = ['物料编号', '产品编号', '供应商', '期望交期', '请求数量包数', '请求数量规格', '请求数量', '建议采购数量（按周）', '库位', '销售订单编号', '特殊要求', '备注'];
      const data = [['LK-WL1', 'LK-CP1', 'SVBC', '2020-10-12', '3', '30', '90', '0', 'B库(零剪库)', 'SO2020100900001', '无', '测试导入']];
      exportExcel(headers, [], data, 'PR导入模板', true, 'xlsx');
    },
    downloadSelectExcelHandle() {
      // 导出已选择项
      const headerList = [];
      const fieldList = [];
      if (this.childMultipleSelection.length) {
        this.downloadExcelLoading = true;
        this.fieldsList.forEach(item => {
          headerList.push(item.name);
        });
        this.childData.data.forEach(item => {
          fieldList.push(item.field);
        });
        import('@/vendor/Export2Excel').then(excel => {
          const tHeader = headerList;
          const filterVal = fieldList;
          // const tHeader = ['Id', 'Title', 'Author', 'Readings', 'Date'];
          // const filterVal = ['id', 'title', 'author', 'pageviews', 'display_time'];
          const list = this.childMultipleSelection;
          const data = this.formatJson(filterVal, list);
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename: this.filename
          });
          // this.$refs.multipleTable[0].clearSelection();
          this.$refs['multipleTable' + this.activeName][0].clearSelection();
          this.downloadExcelLoading = false;
        });
      } else {
        this.$message({
          message: '请选择需要导出的数据！',
          type: 'warning'
        });
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },

    // Upload Edit
    importExcelBeforeUpload(file) {
      let isType = false;
      const fileName = file.name;
      const fileType = fileName.substr(fileName.lastIndexOf('.') + 1);
      const isXLSX = fileType === 'xlsx';
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        this.$message({
          message: '请不要上传大于1M的文件！',
          type: 'error'
        });
        return false;
      }
      if (!isXLSX) {
        this.$message.error('仅支持上传.xlsx后缀文件！');
      }
      if (isXLSX) {
        this.editPartLoading = true;
        isType = true;
      }
      return isType;
    },
    async importExcelSuccess({ results, header }) {
      console.log('导入数据：', results, header);
      if (!this.editData.purchaseType) {
        this.$message({
          type: 'error',
          message: '请选择请求类型！'
        });
        this.editPartLoading = false;
        return false;
      }
      if (!this.editData.virtualInventoryId) {
        this.$message({
          type: 'error',
          message: '请选择入库仓位！'
        });
        this.editPartLoading = false;
        return false;
      }

      // if (this.isDifferenceOrder) {
      //   // 请求类型 差异单
      //   if (!this.editData.saleOrderId) {
      //     this.$message({
      //       type: 'error',
      //       message: '请输入原PR编号！'
      //     });
      //     return false;
      //   }
      // }

      if (results && results.length) {
        let editData = {};
        let uuids = [];
        // 根据物料号批量查询
        const businessTypeList = [];
        const materialCodes = [];
        if (this.isEmptyData) {
          // 空数据
          editData = {
            uuid: '',
            purchaseType: '',
            submitType: '',
            purchaseTypeName: '',
            virtualInventoryId: '',
            virtualInventoryName: '',
            saleOrderId: '',
            customerName: '',
            applicant: this.$store.getters.getUserName,
            applicantName: this.$store.state.realName,
            applicantDepartment: this.$store.getters.getdepartmentId,
            applicantDepartmentName: this.$store.state.currentDepartment.name,
            pricList: []
          };
          editData = Object.assign(editData, this.editData);
          await getUUID().then(res => {
            if (res.code === '0') {
              editData.uuid = res.data;
            }
          });
          this.updateEditModeSet('add', editData);
          editData.editModeSet.inputSet.saleOrderId = false;
          editData.editModeSet.textSet.saleOrderId = true;

          // 备货采购-工装-纱线 + 入库仓位：零剪/职业装仓 库位显示 零剪/职业装工厂库
          // 入库仓位 B0 零剪仓 C0 职业装仓
          if (this.editData.purchaseType == 'B020203' && this.editData.virtualInventoryId == 'B0') {
            this.subInventoryList = this.subInventoryList.filter(siItem => {
              return siItem.subInventoryName == '零剪仓工厂库';
            });
          } else if (this.editData.purchaseType == 'B020203' && this.editData.virtualInventoryId == 'C0') {
            this.subInventoryList = this.subInventoryList.filter(siItem => {
              return siItem.subInventoryName == '职业装仓工厂库';
            });
          }
          this.isEmptyData = false;
        } else {
          editData = JSON.parse(JSON.stringify(this.editData));
          editData.pricList = [];
        }

        // 批量查询参数 businessTypeList
        switch (editData.virtualInventoryId) {
          case 'A0':
            businessTypeList.push('Group');
            break;
          case 'B0':
            businessTypeList.push('Retail');
            break;
          case 'C0':
            businessTypeList.push('Suits');
            break;
          case 'D0':
            businessTypeList.push('Agency');
            break;
          default:
            break;
        }
        this.editData = editData;
        await getBatchUUID(results.length).then(res => {
          if (res.code === '0') {
            uuids = res.data;
          }
        });
        for (let i = 0; i < results.length; i++) {
          const item = results[i];
          const editItemCopy = JSON.parse(JSON.stringify(this.editItemData));
          if (uuids[i]) {
            editItemCopy.uuid = uuids[i];
          } else {
            await getUUID().then(res => {
              if (res.code === '0') {
                editItemCopy.uuid = res.data;
              }
            });
          }

          editItemCopy.subInventoryList = this.subInventoryList;
          editItemCopy.supplierId = editData.supplierId;
          editItemCopy.supplierCode = editData.supplierCode;
          editItemCopy.supplierName = editData.supplierName;
          this.updateEditModeSet('add', editItemCopy);
          editItemCopy.editModeSet.inputSet.saleOrderId = false;
          editItemCopy.editModeSet.textSet.saleOrderId = true;

          if (this.isDifferenceOrder) {
            // 差异单
            editItemCopy.editModeSet.unitSet.requiredPackages = {
              min: '-9999999999'
            };
            editItemCopy.editModeSet.unitSet.requiredQuantityPerPackage = {
              disabled: true
            };
            editItemCopy.editModeSet.selectSet.subInventory.disabled = true;
            editItemCopy.editModeSet.datePickerSet.requiredDate.disabled = true;
          } else {
            editItemCopy.editModeSet.unitSet.requiredQuantityPerPackage = {};
            editItemCopy.editModeSet.selectSet.subInventory.disabled = false;
            editItemCopy.editModeSet.datePickerSet.requiredDate.disabled = false;
          }
          Object.keys(item).map((key) => {
            // console.log('导入Excel表数据：', key, item[key]);  // key=>属性名  obj[key]=>属性值
            if (key === '物料编号') {
              editItemCopy.materialCode = item[key];
              materialCodes.push(editItemCopy.materialCode);
            } else if (key === '产品编号') {
              // editItemCopy.productCode = item[key];
              editItemCopy.productCode = '';
            } else if (key === '供应商') {
              const supplierData = this.supplierList.find(sItem => {
                return sItem.supplierName == item[key];
              });
              editItemCopy.supplierName = item[key];
              editItemCopy.supplierId = supplierData ? supplierData.supplierCode : '';
              editItemCopy.supplierCode = supplierData ? supplierData.supplierId : '';
              editItemCopy.supplierShortName = supplierData ? supplierData.supplierCode : '';
            } else if (key === '期望交期') {
              editItemCopy.requiredDate = item[key].toString().indexOf('-') !== -1 ? item[key] : excelDateFormatToDate(item[key]);
            } else if (key === '请求数量包数') {
              editItemCopy.requiredPackages = item[key];
            } else if (key === '请求数量规格') {
              if (Number(item[key]) < 0) {
                editItemCopy.requiredQuantityPerPackage = (-item[key]);
              } else {
                editItemCopy.requiredQuantityPerPackage = item[key];
              }
            } else if (key === '请求数量') {
              editItemCopy.requiredQuantity = item[key];
            } else if (key === '建议采购数量（按周）') {
              editItemCopy.suggestQuantity = item[key];
            } else if (key === '库位') {
              const subInventoryData = this.subInventoryList.find(siItem => {
                return siItem.subInventoryName == item[key];
              });
              editItemCopy.subInventoryName = item[key];
              editItemCopy.subInventory = subInventoryData ? subInventoryData.subInventory : '';
            } else if (key === '特殊要求') {
              editItemCopy.specialRequire = item[key];
            } else if (key === '销售订单编号' || key === 'SO编号') {
              editItemCopy.saleOrderId = editData.saleOrderId ? editData.saleOrderId : '';
            } else if (key === '备注') {
              editItemCopy.remark = item[key];
            }
            //  else if (key === '原PR行号') {
            //   editItemCopy.originalSn = item[key];
            // } else if (key === '原PR编号') {
            //   editItemCopy.originalPurchaseRequestCode = item[key];
            // } else if (key === '原确认书编号'){
            //   editItemCopy.originalConfirmNum = item[key];
            // }
          });
          editData.pricList.push(editItemCopy);
          this.editData = editData;
          // this.editData.pricList.push(editItemCopy);
        }
        const batchParams = {
          businessType: businessTypeList,
          materialCodeList: materialCodes
        };
        await getBatchQuery(batchParams).then(res => {
          // console.log('批量查询数据：', res);
          if (res.code === '0') {
            const batchData = res.data;
            for (let i = 0; i < this.editData.pricList.length; i++) {
              const prItem = this.editData.pricList[i];
              for (const key in batchData) {
                if (prItem.materialCode == key) {
                  if (!batchData[key]) {
                    prItem.productCode = '';
                    prItem.supplierId = '';
                    prItem.supplierCode = '';
                    prItem.supplierName = '';
                    this.$message({
                      type: 'error',
                      message: `${prItem.materialCode}等物料在物料主数据中不存在`
                    });
                    return false;
                  }
                  const pcArr = [];
                  for (let j = 0; j < batchData[key].productCode.length; j++) {
                    pcArr.push(batchData[key].productCode[j].productCode);
                  }
                  prItem.productCode = pcArr.join(',');
                  prItem.productCodeList = batchData[key].productCode;
                  prItem.supplierId = batchData[key].supplierId;
                  prItem.supplierCode = batchData[key].supplierCode;
                  prItem.supplierName = batchData[key].supplierName;
                  prItem.supplierShortName = batchData[key].supplierShortName;
                  prItem.purchaseMeasurementUnit = batchData[key].purchaseMeasurementUnit;
                  prItem.requiredPackagesumy = this.getrequiredPackagesumy(prItem);
                  this.$set(this.editData.pricList, i, prItem);// 采购请求-导入的数据请求数量未显示
                  this.$forceUpdate();
                }
              }
            }
          }
        });

        // this.editData = editData;
        this.editPartLoading = false;
        setTimeout(() => {
          this.editPartLoading = false;
        }, 10000);
      }
    },
    dialogCancelHandle() {
      this.dialogVisible = false;
    },
    closeHandle(index, item) {
      // 关闭PR单
      this.dialogVisible = true;
      this.dialogType = 'closed';
      this.dialogDesc = '确定关闭？';
      this.closedId = item.id;
    },
    dialogConfirmHandle() {
      // 对话框确认
      switch (this.dialogType) {
        case 'closed':
          closeTransferOrder(this.closedId).then(res => {
            // console.log('列表关闭确认：', res);
            if (res.code === '0') {
              this.dialogVisible = false;
              this.$message({
                type: 'success',
                message: '关闭成功！'
              });
              this.getPurchaseRequestList();
            }
            this.dialogVisible = false;
          });
          break;
        case 'delete':
          deletePurchaseRequest(this.deleteId).then(res => {
            // console.log('删除项：', res);
            if (res.code === '0') {
              this.dialogVisible = false;
              this.$message({
                type: 'success',
                message: '删除成功！'
              });
              if (this.prStatus == 'Draft') {
                this.getPurchaseRequestDraftList();
              } else {
                this.getPurchaseRequestList();
              }
            }
            this.dialogVisible = false;
          });
          break;
        case 'deleteItem':
          deletePurchaseRequestitems(this.deleteItemId).then(res => {
            // console.log('删除项：', res);
            if (res.code === '0') {
              this.dialogVisible = false;
              this.$message({
                type: 'success',
                message: '删除成功！'
              });
              if (this.prStatus == 'Draft') {
                this.getPurchaseRequestDraftList();
              } else {
                this.getPurchaseRequestList();
              }
            }
            this.dialogVisible = false;
          });
          break;
        case 'submit':
          this.updateEditHandle('submit');
          break;
        case 'push':
          pushPurchaseRequestitem(this.pushId, this.pushUserName).then(res => {
            // console.log('推送：', res);
            if (res.code === '0') {
              this.dialogVisible = false;
              this.$message({
                type: 'success',
                message: '推送成功！'
              });
              this.getPurchaseRequestList();
            }
            this.dialogVisible = false;
          });
          break;
        case 'revoke':
          revokePurchaseRequestItem(this.revokeUuid).then(res => {
            console.log('撤销项：', res);
            if (res.code === '0') {
              this.dialogVisible = false;
              this.$message({
                type: 'success',
                message: '撤销成功！'
              });
              this.getPurchaseRequestList();
            }
            this.dialogVisible = false;
          });
          break;
        default:
          break;
      }
    },

    selectAllHandle(selection) {
      // 父项全选事件
      console.log('触发全选事件：', selection);
      this.selectUuidList = [];
      this.childMultipleSelection = [];
      this.multipleSelection = selection;
      if (selection && selection.length) {
        for (let i = 0; i < selection.length; i++) {
          const item = selection[i];
          this.selectUuidList.push(item.uuid);
          for (let j = 0; j < item.priList.length; j++) {
            const cItem = item.priList[j];
            this.childMultipleSelection.push(cItem);
            this.$nextTick(() => {
              if (this.$refs['childMultipleTable_' + item.id] && !Array.isArray(this.$refs['childMultipleTable_' + item.id])) {
                this.$refs['childMultipleTable_' + item.id].toggleRowSelection(cItem, true);
              }
            });
          }
        }
      } else {
        for (let i = 0; i < this.requestList.length; i++) {
          const item = this.requestList[i];
          for (let j = 0; j < item.priList.length; j++) {
            const cItem = item.priList[j];
            this.$nextTick(() => {
              if (this.$refs['childMultipleTable_' + item.id] && !Array.isArray(this.$refs['childMultipleTable_' + item.id])) {
                this.$refs['childMultipleTable_' + item.id].toggleRowSelection(cItem, false);
              }
            });
          }
        }
      }
    },
    expandChange(row, rowList) {
      console.log('树形展开项：', row, rowList);
      if (this.selectUuidList.indexOf(row.uuid) > -1) {
        // 父项被勾选
        // await this.$nextTick();
        for (let i = 0; i < row.priList.length; i++) {
          const cItem = row.priList[i];
          this.$nextTick(() => {
            if (this.$refs['childMultipleTable_' + row.id] && !Array.isArray(this.$refs['childMultipleTable_' + row.id])) {
              this.$refs['childMultipleTable_' + row.id].toggleRowSelection(cItem, true);
            }
          });
        }
      }
    },
    selectHandle(selection, row) {
      console.log('当用户手动勾选数据行的 Checkbox 时触发事件：', selection, row);
      const _this = this;
      const _row = row;
      this.selectUuidList = [];
      this.multipleSelection = selection;
      if (selection && selection.length) {
        // await this.$nextTick();
        for (var i = 0; i < selection.length; i++) {
          const item = selection[i];
          this.selectUuidList.push(item.uuid);
        }
        if (this.selectUuidList.indexOf(_row.uuid) > -1) {
          // 当前项选中状态
          for (let i = 0; i < _row.priList.length; i++) {
            const cItem = _row.priList[i];
            this.$nextTick(() => {
              if (this.$refs['childMultipleTable_' + _row.id] && !Array.isArray(this.$refs['childMultipleTable_' + _row.id])) {
                this.$refs['childMultipleTable_' + _row.id].toggleRowSelection(cItem, true);
              }
            });
            this.childMultipleSelection.push(cItem);
          }
        } else {
          for (let i = 0; i < _row.priList.length; i++) {
            const cItem = _row.priList[i];
            this.$nextTick(() => {
              if (this.$refs['childMultipleTable_' + _row.id] && !Array.isArray(this.$refs['childMultipleTable_' + _row.id])) {
                this.$refs['childMultipleTable_' + _row.id].toggleRowSelection(cItem, false);
              }
            });
            this.childMultipleSelection = this.childMultipleSelection.filter(cmsItem => {
              return cmsItem.uuid !== cItem.uuid;
            });
          }
        }
      } else {
        // 未选中 需删除该父项下所有子项
        this.$refs['childMultipleTable_' + _row.id].clearSelection();
        for (let i = 0; i < row.priList.length; i++) {
          const item = row.priList[i];
          if (this.arrContainObjHandle(this.childMultipleSelection, item) != -1) {
            const index = this.childMultipleSelection.findIndex(x => x.uuid == item.uuid);
            this.childMultipleSelection.splice(index, 1);
          }
        }
      }
    },
    async childSelectHandle(selection, row, parentRow) {
      // 选择子项
      console.log('选择子项：', selection, row, parentRow);
      const uuidList = [];
      for (var i = 0; i < selection.length; i++) {
        const item = selection[i];
        uuidList.push(item.uuid);
      }
      if (uuidList.indexOf(row.uuid) > -1) {
        // 当前项选中状态
        if (this.arrContainObjHandle(this.childMultipleSelection, row) === -1) {
          this.childMultipleSelection.push(row);
        }
      } else {
        // 取消选中
        if (this.arrContainObjHandle(this.childMultipleSelection, row) != -1) {
          const index = this.childMultipleSelection.findIndex(x => x.uuid == row.uuid);
          this.childMultipleSelection.splice(index, 1);
        }
      }
      await this.$nextTick();
      if (selection.length === parentRow.priList.length) {
        // 子项选择个数与当前列表子项个数一致触发当前父项数据全选
        this.$nextTick(() => {
          if (this.$refs.multipleTable && !Array.isArray(this.$refs.multipleTable)) {
            this.$refs.multipleTable.toggleRowSelection(parentRow, true);
          }
        });
      }
      if (!Array.isArray(this.$refs.multipleTable)) {
        this.$refs.multipleTable.toggleRowSelection(parentRow, false);
      }
    },
    async childSelectAllHandle(selection, parentRow) {
      // 子项全选
      console.log('子项全选操作：', selection, parentRow);
      await this.$nextTick();
      if (selection && selection.length) {
        this.$nextTick(() => {
          if (this.$refs.multipleTable && !Array.isArray(this.$refs.multipleTable)) {
            this.$refs.multipleTable.toggleRowSelection(parentRow, true);
          }
        });
        for (let i = 0; i < selection.length; i++) {
          const item = selection[i];
          if (this.arrContainObjHandle(this.childMultipleSelection, item) === -1) {
            this.childMultipleSelection.push(item);
          }
        }
      } else {
        for (let i = 0; i < parentRow.priList.length; i++) {
          const item = parentRow.priList[i];
          if (this.arrContainObjHandle(this.childMultipleSelection, item) != -1) {
            const index = this.childMultipleSelection.findIndex(x => x.uuid == item.uuid);
            this.childMultipleSelection.splice(index, 1);
          }
        }
        if (!Array.isArray(this.$refs.multipleTable)) {
          this.$refs.multipleTable.toggleRowSelection(parentRow, false);
        }
      }
    },
    arrContainObjHandle(arr, obj) {
      var index = arr.findIndex(item => item.uuid == obj.uuid);
      return index;
    },
    checkApprovalProcess(item, index) {
      approvalProcessInfo(item.uuid).then(res => {
        console.log('审批流程：', res);
        if (res.code === '0') {
          const data = res.data[res.data.length - 1];
          if (data == undefined) {
            item.fullName = '';
            item.approvalTime = '';
            item.content = '';
          } else {
            item.fullName = data.fullName;
            item.approvalTime = data.approvalTime;
            item.content = data.content;
          }
          this.$set(this.requestList, index, item);
          this.$forceUpdate();
        }
      });
    },
    addDate() {
      var nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate()
      };
      if (date.date < 10) {
        date.date = '0' + date.date; // 补齐
      }
      if (date.month < 10) {
        date.date = '0' + date.date; // 补齐
      }
      this.latestTime = date.year + '-' + date.month + '-' + date.date;
    },
    async purchaseTypeSelect(val) {
      // 请求类型改变
      console.log('请求类型值改变：', val);
      this.editData.virtualInventoryId = '';
      this.editData.virtualInventoryName = '';
      const isB0orC0 = !!((this.editData.virtualInventoryId == 'B0' || this.editData.virtualInventoryId == 'C0'));
      // let isDifferenceType = (this.editData.purchaseTypeName && this.editData.purchaseTypeName.includes('差异单')) ? true : false;
      if (val) {
        const editDataCopy = JSON.parse(JSON.stringify(this.editData));
        const purchaseTypeData = this.requestTypeList.find(item => {
          return item.dictItemValue == val;
        });
        editDataCopy.purchaseTypeName = purchaseTypeData.dictItemName;

        // if (val == 'B020310') {  // 开发大样-职业装 默认 入库仓位为职业装仓
        //   editDataCopy.virtualInventoryId = 'C0';
        //   editDataCopy.virtualInventoryName = '职业装仓';
        // }
        // if(editDataCopy.purchaseTypeName== '以销定采-订货大货'){
        //  editDataCopy.virtualInventoryId='D0'
        //  editDataCopy.virtualInventoryName='订货仓'
        // }
        if (editDataCopy.purchaseTypeName.split('-')[0] == '以销定采') {
          this.isPurchaseBySale = true;
          this.isDisabledPull = false;
        } else {
          this.isPurchaseBySale = false;
          if (!isB0orC0) {
            this.isDisabledPull = true;
          } else {
            this.isDisabledPull = false;
          }
        }
        if (editDataCopy.purchaseTypeName.includes('差异单')) {
          // 差异单
          this.isDisabledPull = true;
          this.isDifferenceOrder = true;
          this.editFieldsList = this.originalEditFieldsList;
          this.$forceUpdate();
        } else {
          this.isDifferenceOrder = false;
          this.editFieldsList = this.standardEditFieldsList;
          this.$forceUpdate();
        }
        // if (editDataCopy.purchaseTypeName == '备货采购-零剪-坯布/纱线') {
        //   this.isDisabledPull = true;
        // }
        // if(editDataCopy.purchaseTypeName=='采购申请差异单'){
        //    editDataCopy.virtualInventoryId='A0';
        //    editDataCopy.virtualInventoryName='集团仓';
        //    editDataCopy.purchaseTypeName='采购申请差异单';
        //    this.isDisabledPull = true;
        //    this.isDisabledInventoryPull=true;
        //    this.virtualInventorySelect('A0');
        // }else{
        //   this.isDisabledInventoryPull=false;
        // }

        switch (val) {
          case 'B020310':
            // 开发大样-职业装 默认职业装仓
            editDataCopy.virtualInventoryId = 'C0';
            editDataCopy.virtualInventoryName = '职业装仓';
            await this.virtualInventorySelect('C0');
            break;
          case 'B020309':
            // 开发大样-零剪 默认零剪仓
            editDataCopy.virtualInventoryId = 'B0';
            editDataCopy.virtualInventoryName = '零剪仓';
            await this.virtualInventorySelect('B0');
            break;
          case 'B010501':
            // 以销定采-订货大货 默认订货仓
            editDataCopy.virtualInventoryId = 'D0';
            editDataCopy.virtualInventoryName = '订货仓';
            break;
          case 'B020303':
            // 备货采购-零剪-坯布/纱线
            this.isDisabledPull = true;
            break;
          case 'B9901':
            // 采购申请差异单
            editDataCopy.virtualInventoryId = 'A0';
            editDataCopy.virtualInventoryName = '集团仓';
            this.isDisabledPull = true;
            this.isDisabledInventoryPull = true;
            this.virtualInventorySelect('A0');
            break;

          default:
            break;
        }

        this.editData = editDataCopy;
      }
    },
    async virtualInventorySelect(val) {
      // 入库仓位改变
      console.log('入库仓位值改变：', val);
      const isStockUpType = !!((this.editData.purchaseTypeName && this.editData.purchaseTypeName.split('-')[0] != '以销定采'));
      const isDifferenceType = !!((this.editData.purchaseTypeName && this.editData.purchaseTypeName.includes('差异单')));
      if (this.editData.pricList && this.editData.pricList.length) {
        this.editData.pricList.forEach(item => {
          item.subInventory = '';
        });
      }
      console.log(this.editData.purchaseType);
      if (val) {
        const isB0orC0 = !!(val == 'B0' || val == 'C0');
        if (!isB0orC0 && isStockUpType) {
          this.isDisabledPull = true;
        } else if (isDifferenceType) {
          this.isDisabledPull = true;
        } else if (this.editData.purchaseTypeName == '备货采购-零剪-坯布/纱线') {
          this.isDisabledPull = true;
        } else if (this.editData.purchaseType == 'B9901') {
          // 采购申请差异单不可拉取
          this.isDisabledPull = true;
          this.isDisabledInventoryPull = true;
        } else {
          this.isDisabledPull = false;
          this.isDisabledInventoryPull = false;
          this.isEmptyData = true;
        }
        await subInventoryListCache(val).then(res => {
          console.log('库位列表：', res);
          if (res.code === '0') {
            this.subInventoryList = res.data;
          }
        });
        // this.getSubInventoryTypeList(val);
        const editDataCopy = JSON.parse(JSON.stringify(this.editData));
        const virtualInventoryData = this.virtualInventoryList.find(item => {
          return item.index == val;
        });
        // editDataCopy.virtualInventoryId = val;
        editDataCopy.virtualInventoryName = virtualInventoryData.name;
        if (editDataCopy.purchaseTypeName && editDataCopy.purchaseTypeName.includes('坯布/纱线')) {
          // 坯布/纱线 库位只能是工厂库
          this.subInventoryList = this.subInventoryList.filter(x => {
            return x.subInventoryName.includes('工厂库');
          });
        }
        if (editDataCopy.purchaseTypeName && editDataCopy.purchaseTypeName.includes('面料生产')) {
          // 面料生产 库位过滤掉工厂库
          this.subInventoryList = this.subInventoryList.filter(x => {
            return !x.subInventoryName.includes('工厂库');
          });
        }
        this.editData = editDataCopy;
        this.$forceUpdate();
      }
    },
    supplierNameChange(val) {
      // 供应商改变
      console.log('供应商值改变：', val);
      if (val) {
        const editDataCopy = JSON.parse(JSON.stringify(this.editData));
        const supplierData = this.supplierList.find(item => {
          return item.supplierId == val;
        });
        editDataCopy.supplierId = val;
        editDataCopy.supplierCode = supplierData.supplierCode;
        editDataCopy.supplierShortName = supplierData.supplierCode;
        editDataCopy.supplierName = supplierData.supplierName;
        // if (editDataCopy.pricList && editDataCopy.pricList.length) {
        //   for (var i = 0; i < editDataCopy.pricList.length; i++) {
        //     let data = editDataCopy.pricList[i];
        //     if (data.supplierId != editDataCopy.supplierId) {
        //       this.inputSetClassHandle(data, 'materialCode', 'danger');
        //     } else {
        //       this.inputSetClassHandle(data, 'materialCode', '');
        //     }
        //   }
        // }
        this.editData = editDataCopy;
      }
    },
    inputSetClassHandle(item, field, classType) {
      const editModeSet = JSON.parse(JSON.stringify(item.editModeSet));
      const inputSet = JSON.parse(JSON.stringify(item.editModeSet.inputSet));
      switch (classType) {
        case 'danger':
          inputSet[field].class = 'input_single w80p danger';
          break;

        default:
          inputSet[field].class = 'input_single w80p';
          break;
      }
      editModeSet.inputSet = inputSet;
      item.editModeSet = editModeSet;
    },
    async pullDataHandle() {
      // 拉取数据
      if (!this.editData.purchaseType) {
        this.$message({
          type: 'error',
          message: '请选择请求类型！'
        });
        return false;
      }
      if (!this.editData.virtualInventoryId) {
        this.$message({
          type: 'error',
          message: '请选择入库仓位！'
        });
        return false;
      }
      // if (this.isDifferenceOrder) {
      //   // 请求类型 差异单
      //   if (!this.editData.saleOrderId) {
      //     this.$message({
      //       type: 'error',
      //       message: '请输入原PR编号！'
      //     });
      //     return false;
      //   }
      // }
      // if (!this.editData.supplierName) {
      //   this.$message({
      //     type: 'error',
      //     message: '请选择供应商！'
      //   });
      //   return false;
      // }
      let businessType = '';
      let data = {
        uuid: '',
        purchaseType: '',
        submitType: '',
        purchaseTypeName: '',
        virtualInventoryId: '',
        virtualInventoryName: '',
        saleOrderId: '',
        customerName: '',
        applicant: this.$store.getters.getUserName,
        applicantName: this.$store.state.realName,
        applicantDepartment: this.$store.getters.getdepartmentId,
        applicantDepartmentName: this.$store.state.currentDepartment.name,
        pricList: []
      };
      data = Object.assign(data, this.editData);
      // 获取库位列表
      this.getSubInventoryTypeList(data.virtualInventoryId);
      getUUID().then(res => {
        if (res.code === '0') {
          data.uuid = res.data;
        }
      });
      if (this.editData.virtualInventoryId == 'C0') {
        businessType = '1';
      } else if (this.editData.virtualInventoryId == 'B0') {
        businessType = '2';
      }
      if (data.purchaseTypeName.split('-')[0] == '以销定采') {
        if (data.purchaseTypeName.indexOf('订货') != -1) {
          this.orderStatus = '';
        } else {
          this.orderStatus = 2;
        }
        const params = {
          saleOrderId: data.saleOrderId,
          saleOrderType: '',
          orderStatus: this.orderStatus
        };
        console.log(params);
        await pullPurchaseRequest(params).then(res => {
          console.log('拉取以销定采数据：', res);
          if (res.code === '0') {
            if (!res.data) {
              this.$message({
                type: 'error',
                message: `无法拉取销售订单${data.saleOrderId}，请检查${data.saleOrderId}订单状态！`
              });
              return false;
            }
            this.isEmptyData = false;
            data.customerName = res.data.customerName;
            if (res.data.saleOrderItemList.length) {
              this.getBatchQuery(res.data.saleOrderItemList, 'pullData');
              for (var i = 0; i < res.data.saleOrderItemList.length; i++) {
                const pData = res.data.saleOrderItemList[i];
                getUUID().then(res => {
                  if (res.code === '0') {
                    pData.uuid = res.data;
                  }
                });
                this.updateEditModeSet('pull', pData);
                pData.editModeSet.inputSet.saleOrderId = false;
                pData.editModeSet.textSet.saleOrderId = true;
                pData.editModeSet.selectSet.subInventory.disabled = false;
                pData.editModeSet.datePickerSet.requiredDate.disabled = false;
                pData.suggestQuantity = pData.quantity;
                if (data.purchaseTypeName == '以销定采-订货大货') {
                  pData.subInventory = '07';
                  pData.subInventoryName = 'K库(内贸订货库)';
                }
                pData.requiredDate = pData.customerOfferedDate;
                pData.subInventoryList = this.subInventoryList;
                const separator = pData.customerOfferedDate != null && pData.customerOfferedDate != '' ? '/' : '';
                // pData.remark = pData.remarksAndCompanyRemarks + separator + pData.customerOfferedDate;
                pData.specialRequire = pData.companyRemarks;
                // this.getProductCodeHandle(pData)
              }
            }

            setTimeout(() => {
              data.pricList = res.data.saleOrderItemList;
              this.editData = data;
              this.$forceUpdate();
            }, 1000);
            // this.$set(this.editData, 'pricList', res.data.saleOrderItemList);
          }
        });
      } else {
        const params = {
          businessType: businessType,
          unit: 'W',
          startTime: getNowDate()
        };
        await pullPlanPurchaseRequest(params).then(res => {
          console.log('拉取备货计划数据：', res);
          if (res.code === '0') {
            this.isDisabledPull = true;
            if (!res.data || !res.data.length) {
              this.$message({
                type: 'error',
                message: '无数据可拉取！'
              });
              return false;
            }
            this.isEmptyData = false;

            for (var i = 0; i < res.data.length; i++) {
              const pData = res.data[i];
              getUUID().then(res => {
                if (res.code === '0') {
                  pData.uuid = res.data;
                }
              });
              this.updateEditModeSet('pull', pData);
              if (this.isDifferenceOrder) {
                // 差异单
                pData.editModeSet.unitSet.requiredPackages = {
                  min: '-9999999999'
                };
              }
              pData.editModeSet.inputSet.saleOrderId = false;
              pData.editModeSet.textSet.saleOrderId = true;
              if (data.purchaseTypeName == '以销定采-订货大货') {
                pData.subInventory = '07';
                pData.subInventoryName = 'K库(内贸订货库)';
              }
              // pData.suggestQuantity = pData.readyPlanQuantity;
              pData.suggestQuantity = pData.adviceQuantity;
              pData.subInventoryList = this.subInventoryList;

              // this.getProductCodeHandle(pData);
            }
            this.getBatchQuery(res.data);
            data.pricList = res.data;
            this.editData = data;
            this.$forceUpdate();
          }
        });
      }
    },

    async addEditHandle() {
      // 添加编辑项
      if (!this.editData.purchaseType) {
        this.$message({
          type: 'error',
          message: '请选择请求类型！'
        });
        return false;
      }
      if (!this.editData.virtualInventoryId) {
        this.$message({
          type: 'error',
          message: '请选择入库仓位！'
        });
        return false;
      }
      // if (this.isDifferenceOrder) {
      //   // 请求类型 差异单
      //   if (!this.editData.saleOrderId) {
      //     this.$message({
      //       type: 'error',
      //       message: '请输入原PR编号！'
      //     });
      //     return false;
      //   }
      // }
      // if (!this.editData.supplierName) {
      //   this.$message({
      //     type: 'error',
      //     message: '请选择供应商！'
      //   });
      //   return false;
      // }
      // this.isDisabledAddEdit = true;

      this.$nextTick(() => {
        this.$refs.editTable.targetingLastPageHandle();
      });
      if (this.isEmptyData) {
        // 空数据
        let data = {
          uuid: '',
          purchaseType: '',
          submitType: '',
          purchaseTypeName: '',
          virtualInventoryId: '',
          virtualInventoryName: '',
          saleOrderId: '',
          customerName: '',
          applicant: this.$store.getters.getUserName,
          applicantName: this.$store.state.realName,
          applicantDepartment: this.$store.getters.getdepartmentId,
          applicantDepartmentName: this.$store.state.currentDepartment.name,
          pricList: []
        };
        const editItemCopy = JSON.parse(JSON.stringify(this.editItemData));
        data = Object.assign(data, this.editData);
        console.log(data, 'data');
        this.updateEditModeSet('add', editItemCopy);

        editItemCopy.editModeSet.inputSet.saleOrderId = false;
        editItemCopy.editModeSet.textSet.saleOrderId = true;
        //     if(editItemCopy.purchaseTypeName=='开发大样-职业装') {
        //   this.editItemData.subInventoryName='职业装大样库'
        // }
        // if(editItemCopy.purchaseTypeName=='开发大样-零剪') {
        //   this.editItemData.subInventoryName='零剪大样库'
        // }
        // 差异单
        if (this.isDifferenceOrder) {
          editItemCopy.editModeSet.unitSet.requiredPackages = {
            min: '-9999999999'
          };

          // 差异单改造
          var date = new Date();
          const time = getNowFormatDate(date);
          // editItemCopy.subInventoryList = this.subInventoryList;
          editItemCopy.virtualInventoryName = '集团仓';
          editItemCopy.subInventory = 'X2';
          editItemCopy.subInventoryName = '集团暂存库';
          editItemCopy.virtualInventoryId = 'A0';
          editItemCopy.requiredDate = time;
          editItemCopy.editModeSet.selectSet.subInventory.disabled = true;
          editItemCopy.editModeSet.datePickerSet.requiredDate.disabled = true;
          editItemCopy.editModeSet.unitSet.requiredQuantityPerPackage = {
            disabled: true
          };
        } else {
          editItemCopy.editModeSet.selectSet.subInventory.disabled = false;
          editItemCopy.editModeSet.datePickerSet.requiredDate.disabled = false;
          editItemCopy.editModeSet.unitSet.requiredQuantityPerPackage = {
            disabled: false
          };
        }

        // 获取库位列表
        // await this.getSubInventoryTypeList(this.editData.virtualInventoryId);

        // 备货采购-工装-纱线 + 入库仓位：零剪/职业装仓 库位显示 零剪/职业装工厂库
        // 入库仓位 B0 零剪仓 C0 职业装仓
        if (this.editData.purchaseType == 'B020203' && this.editData.virtualInventoryId == 'B0') {
          this.subInventoryList = this.subInventoryList.filter(siItem => {
            return siItem.subInventoryName == '零剪仓工厂库';
          });
        } else if (this.editData.purchaseType == 'B020203' && this.editData.virtualInventoryId == 'C0') {
          this.subInventoryList = this.subInventoryList.filter(siItem => {
            return siItem.subInventoryName == '职业装仓工厂库';
          });
        } else if (this.editData.purchaseType == 'B020310' && this.editData.virtualInventoryId == 'C0') {
          // 开发大样-职业装 默认职业装大样库
          editItemCopy.subInventory = '21';
          editItemCopy.subInventoryName = '职业装大样库';
        } else if (this.editData.purchaseType == 'B020309' && this.editData.virtualInventoryId == 'B0') {
          // 开发大样-零剪 默认零剪产品库
          editItemCopy.subInventory = '08';
          editItemCopy.subInventoryName = '零剪产品库';
          // // 开发大样-零剪 默认零剪大样库
          // editItemCopy.subInventory = '21';
          // editItemCopy.subInventoryName = '零剪大样库';
        }

        editItemCopy.subInventoryList = this.subInventoryList;
        editItemCopy.supplierId = data.supplierId;
        editItemCopy.supplierCode = data.supplierCode;
        editItemCopy.supplierName = data.supplierName;
        getUUID().then(res => {
          if (res.code === '0') {
            data.uuid = res.data;
          }
        });
        getUUID().then(res => {
          if (res.code === '0') {
            editItemCopy.uuid = res.data;
            data.pricList.push(editItemCopy);
            this.isEmptyData = false;
          }
        });
        this.editData = data;
      } else {
        const editDataCopy = JSON.parse(JSON.stringify(this.editData));
        const editItemCopy = JSON.parse(JSON.stringify(this.editItemData));
        this.updateEditModeSet('add', editItemCopy);

        // if (this.editData.purchaseTypeName.split('-')[0] == '以销定采') {
        editItemCopy.editModeSet.inputSet.saleOrderId = false;
        editItemCopy.editModeSet.textSet.saleOrderId = true;
        // }
        // 差异单
        if (this.isDifferenceOrder) {
          editItemCopy.editModeSet.unitSet.requiredPackages = {
            min: '-9999999999'
          };

          // 差异单改造
          var date = new Date();
          const time = getNowFormatDate(date);
          // editItemCopy.subInventoryList = this.subInventoryList;
          editItemCopy.virtualInventoryName = '集团仓';
          editItemCopy.subInventory = 'X2';
          editItemCopy.subInventoryName = '集团暂存库';
          editItemCopy.virtualInventoryId = 'A0';
          editItemCopy.requiredDate = time;
          editItemCopy.editModeSet.selectSet.subInventory.disabled = true;
          editItemCopy.editModeSet.datePickerSet.requiredDate.disabled = true;
        } else {
          editItemCopy.editModeSet.selectSet.subInventory.disabled = false;
          editItemCopy.editModeSet.datePickerSet.requiredDate.disabled = false;
        }

        // 备货采购-工装-纱线 + 入库仓位：零剪/职业装仓 库位显示 零剪/职业装工厂库
        // 入库仓位 B0 零剪仓 C0 职业装仓
        if (this.editData.purchaseType == 'B020203' && this.editData.virtualInventoryId == 'B0') {
          this.subInventoryList = this.subInventoryList.filter(siItem => {
            return siItem.subInventoryName == '零剪仓工厂库';
          });
        } else if (this.editData.purchaseType == 'B020203' && this.editData.virtualInventoryId == 'C0') {
          this.subInventoryList = this.subInventoryList.filter(siItem => {
            return siItem.subInventoryName == '职业装仓工厂库';
          });
        } else if (this.editData.purchaseType == 'B020310' && this.editData.virtualInventoryId == 'C0') {
          // 开发大样-职业装 默认职业装大样库
          editItemCopy.subInventory = '21';
          editItemCopy.subInventoryName = '职业装大样库';
        } else if (this.editData.purchaseType == 'B020309' && this.editData.virtualInventoryId == 'B0') {
          // 开发大样-零剪 默认零剪产品库
          editItemCopy.subInventory = '08';
          editItemCopy.subInventoryName = '零剪产品库';
          // // 开发大样-零剪 默认零剪大样库
          // editItemCopy.subInventory = '21';
          // editItemCopy.subInventoryName = '零剪大样库';
        }

        editItemCopy.subInventoryList = this.subInventoryList;
        editItemCopy.supplierId = editDataCopy.supplierId;
        editItemCopy.supplierCode = editDataCopy.supplierCode;
        editItemCopy.supplierName = editDataCopy.supplierName;
        await getUUID().then(res => {
          if (res.code === '0') {
            editItemCopy.uuid = res.data;
            // editDataCopy.pricList=[];
            editDataCopy.pricList.push(editItemCopy);
          }
        });
        this.editData = editDataCopy;
      }
    },
    deleteEditItemHandle(index, item) {
      // 删除编辑项
      const editDataCopy = JSON.parse(JSON.stringify(this.editData));
      editDataCopy.pricList.splice(index, 1);
      this.editData = editDataCopy;
    },

    getrequiredPackagesumy(data) {
      if (data.purchaseMeasurementUnit == '米') {
        data.requiredPackagesumy = (data.requiredPackages ? data.requiredPackages : 0) + '包 ' + (data.requiredQuantityPerPackage ? data.requiredQuantityPerPackage : 0) + '米/包 ' + (data.requiredQuantity ? data.requiredQuantity : 0) + '米';
      } else {
        data.requiredPackagesumy = (data.requiredQuantity ? data.requiredQuantity : 0) + (data.purchaseMeasurementUnit ? data.purchaseMeasurementUnit : '条');
      }
      return data.requiredPackagesumy;
    },
    async editHandle(index, item) {
      // 编辑
      console.log('编辑项：', item);
      this.editType = 'edit';
      this.prStatus = this.activeName;
      this.isEmptyData = false; // 父项数据是否可编辑
      this.isDisabledInventoryPull = false;
      let editDataCopy = JSON.parse(JSON.stringify(this.editData));
      if (item.purchaseTypeName == '采购申请差异单') {
        item.virtualInventoryId = 'A0';
        item.virtualInventoryName = '集团仓';
        this.isDisabledInventoryPull = true;
      }
      // 获取库位列表
      await this.getSubInventoryTypeList(item.virtualInventoryId);

      searchPurchaseRequest(item.id).then(res => {
        // console.log('采购申请编辑项查询数据：', res);
        if (res.code === '0' && res.data) {
          editDataCopy = res.data;
          console.log(editDataCopy.purchaseTypeName);
          if (editDataCopy.purchaseTypeName && editDataCopy.purchaseTypeName.split('-')[0] == '以销定采') {
            this.isPurchaseBySale = true;
          } else {
            this.isPurchaseBySale = false;
          }
          if (editDataCopy.purchaseTypeName.includes('差异单')) {
            // 差异单
            this.editFieldsList = this.originalEditFieldsList;
            this.$forceUpdate();
          } else {
            this.editFieldsList = this.standardEditFieldsList;
            this.$forceUpdate();
          }

          editDataCopy.pricList = res.data.priList;
          for (var i = 0; i < editDataCopy.pricList.length; i++) {
            const data = editDataCopy.pricList[i];
            var date = new Date();
            const time = getNowFormatDate(date);
            data.subInventoryList = this.subInventoryList;
            data.requiredPackagesumy = this.getrequiredPackagesumy(data);
            // this.getProductCodeHandle(data);
            this.updateEditModeSet('edit', data);
            data.editModeSet.inputSet.saleOrderId = false;
            data.editModeSet.textSet.saleOrderId = true;
            if (editDataCopy.purchaseTypeName.includes('差异单')) {
              this.isDifferenceOrder = true;
              // 差异单
              data.editModeSet.unitSet.requiredPackages = {
                min: '-9999999999'
              };

              // 差异单改造
              editDataCopy.virtualInventoryId = 'A0';
              editDataCopy.virtualInventoryName = '集团仓';
              data.subInventory = 'X2';
              data.subInventoryName = '集团暂存库';
              data.virtualInventoryId = 'A0';
              data.virtualInventoryName = '集团仓';
              data.requiredDate = time;
              // data.editModeSet.selectSet.subInventory.disabled=true;
              data.editModeSet.datePickerSet.requiredDate.disabled = true;
              data.editModeSet.unitSet.requiredQuantityPerPackage = {
                disabled: true
              };
              if (data.requiredQuantityPerPackage < 0) {
                data.requiredQuantityPerPackage = -(data.requiredQuantityPerPackage);
                data.requiredQuantity = data.requiredPackages * data.requiredQuantityPerPackage;
              }
            } else {
              this.isDifferenceOrder = false;

              // 差异单改造
              data.editModeSet.selectSet.subInventory.disabled = false;
              data.editModeSet.datePickerSet.requiredDate.disabled = false;
              data.editModeSet.unitSet.requiredQuantityPerPackage = {
                disabled: false
              };
            }
          }

          this.editData = editDataCopy;
          this.$forceUpdate();
          console.log(this.editData);
          this.isShowEdit = true;
        }
      });
    },
    submitHandle(index, item) {
      // 列表提交
      this.editData = item;
      this.dialogVisible = true;
      this.dialogType = 'submit';
      this.dialogDesc = '确定提交审批？';
    },
    pushHandle(index, item) {
      // 推送
      this.dialogVisible = true;
      this.dialogType = 'push';
      this.dialogDesc = '确定推送到VMB？';
      this.pushId = item.purchaseRequestCode;
      this.pushUserName = this.$store.state.userName;
    },
    deleteHandle(index, item) {
      // 列表删除
      this.dialogVisible = true;
      this.dialogType = 'delete';
      this.deleteId = item.id;
      this.dialogDesc = '确定删除？';
    },
    editDeleteHandle(index, item) {
      this.dialogVisible = true;
      this.dialogType = 'deleteItem';
      this.dialogDesc = '确定删除？';
      this.deleteItemId = item.id;
    },
    revokeHandle(index, item) {
      // 列表撤销（已通过）
      this.dialogVisible = true;
      this.dialogType = 'revoke';
      this.dialogDesc = '确定撤销？';
      this.revokeUuid = item.uuid;
    },
    cancelEditHandle() {
      // 编辑取消
      this.isShowEdit = false;
    },
    async updateEditHandle(type) {
      // 编辑保存/提交审批
      const list = this.editData.pricList;
      // if (this.editData.supplierName) {
      //   let supplierData = this.supplierList.find(item => {
      //     return item.supplierName == this.editData.supplierName;
      //   });
      //   this.editData.supplierId = supplierData.supplierId;
      // }

      // if (!this.editData.supplierName || !this.editData.supplierId) {
      //   this.$message({
      //     type: 'error',
      //     message: '请选择供应商！'
      //   });
      //   return false;
      // }
      if (!list) {
        this.$message({
          type: 'error',
          message: '请添加数据！'
        });
        return false;
      }
      if (!this.editData.purchaseType) {
        this.$message({
          type: 'error',
          message: '请选择请求类型！'
        });
        return false;
      }
      if (!this.editData.virtualInventoryId) {
        this.$message({
          type: 'error',
          message: '请选择入库仓位！'
        });
        return false;
      }
      await this.getSubInventoryTypeList(this.editData.virtualInventoryId);
      for (var i = 0; i < list.length; i++) {
        const data = list[i];
        if (!data.materialCode) {
          this.$message({
            type: 'error',
            message: '请输入物料编号！'
          });
          return false;
        }
        // if (!data.productCode) {
        //   this.$message({
        //     type: 'error',
        //     message: '请重新输入物料编号查询产品编号！'
        //   });
        //   return false;
        // }
        if (!data.supplierId) {
          this.$message({
            type: 'error',
            message: '请重新输入物料编号查询供应商！'
          });
          return false;
        }
        if (!data.requiredDate) {
          this.$message({
            type: 'error',
            message: '请选择期望交期！'
          });
          return false;
        }
        if (data.purchaseMeasurementUnit == '米') {
          if (!data.requiredPackages) {
            this.$message({
              type: 'error',
              message: '请输入请求数量！'
            });
            return false;
          }
          if (!verificationQuantity(this, data.requiredPackages, 8, 2, '请求数量')) {
            return false;
          }
          if (!data.requiredQuantityPerPackage) {
            this.$message({
              type: 'error',
              message: '请输入请求数量！'
            });
            return false;
          }
          if (!verificationQuantity(this, data.requiredQuantityPerPackage, 8, 2, '请求数量')) {
            return false;
          }
          // if (!data.requiredQuantity) {
          //   this.$message({
          //     type: 'error',
          //     message: '请输入请求数量！'
          //   });
          //   return false;
          // }
          // if (!verificationQuantity(this, data.requiredQuantity, 8, 2, '请求数量')) {
          //   return false;
          // }
        }
        // else {
        //   if (!data.requiredQuantity) {
        //     this.$message({
        //       type: 'error',
        //       message: '请输入请求数量！'
        //     });
        //     return false;
        //   }
        //   if (!verificationQuantity(this, data.requiredQuantity, 8, 2, '请求数量')) {
        //     return false;
        //   }
        // }
        if (!data.subInventory) {
          this.$message({
            type: 'error',
            message: '请选择库位！'
          });
          return false;
        }
        if (!data.subInventoryList || !data.subInventoryList.length) {
          data.subInventoryList = this.subInventoryList;
        }
        const subInventoryData = data.subInventoryList.find(sItem => {
          return sItem.subInventory == data.subInventory;
        });
        data.subInventoryName = subInventoryData.subInventoryName;
        if (this.isDifferenceOrder) {
          // 差异单
          if (data.requiredPackages < 0) {
            this.$message({
              type: 'error',
              message: '差异单下包数不能为负数！'
            });
            return false;
          }
        }
      }

      if (type == 'save') {
        this.isSaveDisabled = true;
        // if (this.prStatus == 'Rejected') {
        if (this.editData.prStatus == 'Rejected') {
          // 驳回保存
          this.editData.submitType = '3';
        } else {
          this.editData.submitType = '1';
        }
      } else {
        this.isSubmitDisabled = true;
        // if (this.prStatus == 'Rejected') {
        if (this.editData.prStatus == 'Rejected') {
          // 驳回提交审批
          this.editData.submitType = '4';
        } else {
          this.editData.submitType = '2';
        }
      }
      if (this.prStatus == '0') {
        this.prStatus = '';
      }
      this.editData.applicant ? '' : this.editData.applicant = this.$store.state.userName;
      this.editData.applicantName ? '' : this.editData.applicantName = this.$store.state.realName;
      this.editData.applicantDepartment ? '' : this.editData.applicantDepartment = this.$store.state.currentDepartment.id;
      this.editData.applicantDepartmentName ? '' : this.editData.applicantDepartmentName = this.$store.state.currentDepartment.name;
      // this.editPartLoading = true;
      if (this.editType == 'add') {
        // 新增
        addPurchaseRequest(this.editData).then(res => {
          // console.log('保存新增接口：', res);
          if (res.code === '0') {
            if (type == 'save') {
              this.activeName = 'Draft';
              this.prStatus = 'Draft';
              this.getPurchaseRequestDraftList();
              this.$message({
                type: 'success',
                message: '保存成功！'
              });
            } else {
              this.activeName = 'Approving';
              this.prStatus = 'Approving';
              this.getPurchaseRequestList();
              this.$message({
                type: 'success',
                message: '提交审批成功！'
              });
            }
            this.isShowEdit = false;
          }
          if (type == 'save') {
            this.isSaveDisabled = false;
          } else {
            if (this.dialogVisible) {
              this.dialogVisible = false;
            }
            this.isSubmitDisabled = false;
          }
          // this.editPartLoading = false;
        });
      } else {
        // 编辑修改
        updatePurchaseRequest(this.editData).then(res => {
          // console.log('编辑修改接口：', res);
          if (res.code === '0') {
            if (type == 'save') {
              if (this.prStatus == 'Draft') {
                this.getPurchaseRequestDraftList();
              } else {
                this.getPurchaseRequestList();
              }
              this.$message({
                type: 'success',
                message: '保存成功！'
              });
            } else {
              this.activeName = 'Approving';
              this.prStatus = 'Approving';
              this.getPurchaseRequestList();
              this.$message({
                type: 'success',
                message: '提交审批成功！'
              });
            }
            this.isShowEdit = false;
          }
          if (type == 'save') {
            this.isSaveDisabled = false;
          } else {
            if (this.dialogVisible) {
              this.dialogVisible = false;
            }
            this.isSubmitDisabled = false;
          }
          // this.editPartLoading = false;
        });
      }
    },
    getPurchaseRequestList() {
      // 采购申请列表
      this.tableLoading = true;
      const data = {
        beginTime: this.beginTime,
        endTime: this.endTime,
        productCode: this.productCode,
        purchaseType: this.purchaseType,
        prStatus: this.prStatus,
        sort: this.sort,
        planTransferDate:this.latestTime,
        materialCode:this.materialCode,
        purchaseRequestCode: this.purchaseRequestCode,
        saleOrderId: this.saleOrderId
      };
      purchaseRequestList(this.currentPage, this.pageSize, data).then(res => {
        console.log('采购申请列表：', res);
        if (res.code === '0') {
          this.total = res.data.total;
          this.requestList = res.data.records;
          for (let i = 0; i < this.requestList.length; i++) {
            const item = this.requestList[i];
            item.status = item.prStatus;
            item.createTime = item.createTime ? timeFormat(item.createTime) : '';
            if (item.offeredDate && item.offeredDate != undefined) {
              item.offeredDate = timeFormat(item.offeredDate);
            } else {
              item.offeredDate = '';
            }
            item.prStatusName = statusTypeHandle(item.prStatus);

            // 列表组件编辑项
            // switch (item.prStatus) {
            //   case 'Draft':
            //     item.actions = {
            //       hasEdit: true,
            //       hasSubmit: true,
            //       hasDelete: true
            //     };
            //     break;
            //   case 'Rejected':
            //     item.actions = {
            //       hasEdit: true,
            //       hasSubmit: true,
            //       hasDelete: true
            //     };
            //     break;
            //   case 'Terminated':
            //     item.actions = {
            //       hasDelete: true
            //     };
            //     break;
            //   default:
            //     item.actions = {};
            //     break;
            // }
            for (var j = 0; j < item.priList.length; j++) {
              const cItem = item.priList[j];
              if (item.prStatus === 'Approved') {
                cItem.cancelStatus === true ? cItem.cancelStatusName = '已取消' : cItem.cancelStatusName = '正常';
              } else {
                cItem.cancelStatusName = '';
              }
              if (cItem.requiredQuantityPerPackage < 0) {
                cItem.requiredQuantityPerPackage = -(cItem.requiredQuantityPerPackage);
                cItem.requiredQuantity = cItem.requiredPackages * cItem.requiredQuantityPerPackage;
              }
              this.updateEditModeSet('edit', item.priList[j]);
            }
            item.pricList = item.priList;
          }
          this.tableLoading = false;
        } else {
          this.tableLoading = false;
        }
      });
      setTimeout(() => {
        this.tableLoading = false;
      }, 10000);
    },
    getPurchaseRequestDraftList() {
      // 采购申请草稿列表
      this.tableLoading = true;
      const data = {
        beginTime: this.beginTime,
        endTime: this.endTime,
        productCode: this.productCode,
        purchaseType: this.purchaseType,
        prStatus: this.prStatus,
        sort: this.sort,
        planTransferDate: this.latestTime,
        materialCode: this.materialCode,
        purchaseRequestCode: this.purchaseRequestCode
      };
      purchaseRequestDraftList(this.currentPage, this.pageSize, data).then(res => {
        console.log('采购申请草稿列表：', res);
        if (res.code === '0') {
          this.total = res.data.total;
          this.requestList = res.data.records;
          for (let i = 0; i < this.requestList.length; i++) {
            const item = this.requestList[i];
            item.status = item.prStatus;
            item.createTime = item.createTime ? timeFormat(item.createTime) : '';
            if (item.offeredDate && item.offeredDate != undefined) {
              item.offeredDate = timeFormat(item.offeredDate);
            } else {
              item.offeredDate = '';
            }
            item.prStatusName = statusTypeHandle(item.prStatus);
            // 列表组件编辑项
            // switch (item.prStatus) {
            //   case 'Draft':
            //     item.actions = {
            //       hasEdit: true,
            //       hasSubmit: true,
            //       hasDelete: true
            //     };
            //     break;
            //   case 'Rejected':
            //     item.actions = {
            //       hasEdit: true,
            //       hasSubmit: true,
            //       hasDelete: true
            //     };
            //     break;
            //   case 'Terminated':
            //     item.actions = {
            //       hasDelete: true
            //     };
            //     break;
            //   default:
            //     item.actions = {};
            //     break;
            // }
            for (var j = 0; j < item.priList.length; j++) {
              const cItem = item.priList[j];
              cItem.cancelStatusName = '';
              if (cItem.requiredQuantityPerPackage < 0) {
                cItem.requiredQuantityPerPackage = -(cItem.requiredQuantityPerPackage);
                cItem.requiredQuantity = cItem.requiredPackages * cItem.requiredQuantityPerPackage;
              }
              this.updateEditModeSet('edit', item.priList[j]);
            }
            item.pricList = item.priList;
          }
          this.tableLoading = false;
        } else {
          this.tableLoading = false;
        }
      });
      setTimeout(() => {
        this.tableLoading = false;
      }, 10000);
    },
    async getMeasurementUnit(item) {
      // 获取计量单位
      await getMeasurementUnitCache(item.materialCode).then(res => {
        // console.log('计量单位：', res);
        if (res.code === '0') {
          item.purchaseMeasurementUnit = res.data;
          item.requiredPackagesumy = this.getrequiredPackagesumy(item);
          this.$forceUpdate();
        }
      });
    },
    getSupplierInfo(item) {
      // 根据物料编号获取供应商
      // getSupplierByMC(item.materialCode).then(res => {
      //   console.log('根据物料编号获取供应商：', res);
      //   if (res.code === '0') {
      //     let obj = res.data;
      //     let arr = Object.keys(obj);
      //     if (!arr.length) {
      //       item.supplierName = '';
      //       item.supplierId = '';
      //       this.$message({
      //         type: 'error',
      //         message: '该物料编号下无供应商！'
      //       });
      //       return false;
      //     }
      //     let value = Object.values(obj);
      //     let keys = Object.keys(obj);
      //     item.supplierName = value[0];
      //     item.supplierId = keys[0];
      //     this.$forceUpdate();
      //   }
      // });
      existMaterialCodeCache(item.materialCode).then(res => {
        if (res.code === '0') {
          if (!res.data) {
            this.$message({
              type: 'error',
              message: '查无此物料编号！'
            });
            // item.materialCode = '';
            // item.productCode = '';
            return false;
          }
          if (!res.data.supplierName && !res.data.supplierId) {
            item.supplierName = '';
            item.supplierId = '';
            this.$message({
              type: 'error',
              message: '该物料编号下无供应商！'
            });
            return false;
          }
          item.supplierName = res.data.supplierName;
          item.supplierId = res.data.supplierId;
        }
      });
    },

   async getRequestTypeList() {
      // 请求类型
      //  this.requestTypeList =  dictListCache('purchaseType').data;
      let res = await dictList('purchaseType')
      this.requestTypeList =  res.data;
    },
    async getVirtualInventory() {
      // 入库仓位
      // constDictListCache('VirtualInventory').then(res => {
      //   console.log('入库仓位：', res);
      //   if (res.code === '0') {
      //     this.virtualInventoryList = res.data;
      //   }
      // });
      let res = await constDictListCache('VirtualInventory')
      this.virtualInventoryList = res.data;
    },
    async getSubInventoryTypeList(virtualInventoryId) {
      // 库位
      await subInventoryListCache(virtualInventoryId).then(res => {
        console.log('库位列表：', res);
        if (res.code === '0') {
          this.subInventoryList = res.data;
          this.$forceUpdate();
        }
      });
    },
    getSupplierList() {
      // 供应商列表
      supplierListCache().then(res => {
        console.log('供应商列表：', res);
        if (res.code === '0') {
          this.supplierList = res.data;
        }
      });
    },
    async getProductCodeHandle(data) {
      // 通过物料编号获取产品编号
      data.productCode = '';
      await getProductCodeByMC(data.materialCode, '', data.productCode).then(res => {
        console.log('通过物料编号获取产品编号：', res);
        if (res.code === '0') {
          if (!res.data || !res.data.length) {
            this.$message({
              type: 'error',
              message: '该物料编号下未查询到产品编号！'
            });
            return false;
          }
          const pcArr = [];
          for (var i = 0; i < res.data.length; i++) {
            pcArr.push(res.data[i].productCode);
          }
          data.productCode = pcArr.join(',');
          data.productCodeList = res.data;
          this.$forceUpdate();
        }
      });
    },
    async getBatchQuery(data, tag) {
      console.log('data,tag', data, tag);
      var _data = data;
      data.productCode = '';
      // let materialCodes=[data.materialCode];
      const materialCodes = [];
      for (var i = 0; i < data.length; i++) {
        materialCodes.push(data[i].materialCode);
      }
      const businessTypeList = [];
      switch (this.editData.virtualInventoryId) {
        case 'A0':
          businessTypeList.push('Group');
          break;
        case 'B0':
          businessTypeList.push('Retail');
          break;
        case 'C0':
          businessTypeList.push('Suits');
          break;
        case 'D0':
          businessTypeList.push('Agency');
          break;
        default:
          break;
      }
      const sumList = {
        businessType: businessTypeList,
        materialCodeList: materialCodes
      };
      await getBatchQueryCache(sumList).then(res => {
        var data = [res.data];
        console.log('data', data);
        var isArr = true;
        for (var j = 0; j < _data.length; j++) {
          data.map((item, i) => {
            for (const key in item) {
              if (key == _data[j].materialCode) {
                if (item[key] == null) {
                  data[i].supplierName = '';
                  data[i].productCode = '';
                  // this.$message({
                  //   type: 'error',
                  //   message: '该物料编号下无供应商 产品编号！'
                  // });
                  return false;
                }
                _data[j].supplierName = item[key].supplierName;
                _data[j].supplierId = item[key].supplierId;
                _data[j].supplierCode = item[key].supplierId;
                _data[j].supplierShortName = item[key].supplierShortName;
                _data[j].purchaseMeasurementUnit = item[key].purchaseMeasurementUnit;
                if (tag != 'pullData') {
                  // 区分数组拉取的时候产品编码直接取返回的数据不经过集合处理
                  const pcArr = [];
                  for (var i = 0; i < item[key].productCode.length; i++) {
                    pcArr.push(item[key].productCode[i].productCode);
                  }
                  _data[j].productCode = pcArr.join(',');
                  this.$forceUpdate();
                }
              }
            }
          });
        }
        // this.$set(this.editData, 'pricList', data);
        // if(isArr==false){
        //     this.$message({
        //       type: 'error',
        //       message: '该物料编号下无产品编号！'
        //     });
        // }
      });
    },
    switchTabHandle(tab) {
      // Tab切换
      console.log('Tab切换：', tab, this.activeName);
      // this.tableLoading = true;
      this.currentPage = 1;
      this.multipleSelection = [];
      this.childMultipleSelection = [];
      this.selectUuidList = []; // 勾选项清空
      this.requestList = [];
      switch (tab.name) {
        case '':
          // 全部
          this.prStatus = '';
          this.fieldsList = this.moreFieldsList;
          this.childData = this.moreChildData;
          this.getPurchaseRequestList();
          break;
        case 'Draft':
          // 草稿
          this.prStatus = 'Draft';
          this.fieldsList = this.lessFieldsList;
          this.childData = this.lessChildData;
          this.getPurchaseRequestDraftList();
          break;
        case 'Approving':
          // 审批中
          this.prStatus = 'Approving';
          this.fieldsList = this.lessFieldsList;
          this.childData = this.lessChildData;
          this.getPurchaseRequestList();
          break;
        case 'Approved':
          // 已通过
          this.prStatus = 'Approved';
          this.fieldsList = this.moreFieldsList;
          this.childData = this.moreChildData;
          this.getPurchaseRequestList();
          break;
        case 'Rejected':
          // 已驳回
          this.prStatus = 'Rejected';
          this.fieldsList = this.lessFieldsList;
          this.childData = this.lessChildData;
          this.getPurchaseRequestList();
          break;
        case 'Terminated':
          // 已终止
          this.prStatus = 'Terminated';
          this.fieldsList = this.lessFieldsList;
          this.childData = this.lessChildData;
          this.getPurchaseRequestList();
          break;
        default:
          this.prStatus = '';
          this.fieldsList = this.moreFieldsList;
          this.childData = this.moreChildData;
          break;
      }
    },
    dateValChange(val) {
      // 创建时间变化
      // this.beginTime = val[0];
      // this.endTime = val[1];
      this.searchHandle();
    },
    searchHandle() {
      // 搜索
      this.currentPage = 1;
      this.requestList = [];
      if (this.dateVal) {
        this.beginTime = this.dateVal[0];
        this.endTime = this.dateVal[1];
      } else {
        this.beginTime = '';
        this.endTime = '';
      }
      if (this.prStatus == 'Draft') {
        this.getPurchaseRequestDraftList();
      } else {
        this.getPurchaseRequestList();
      }
    },
    async addHandle() {
      // 新增
      this.editFieldsList = this.standardEditFieldsList;
      this.editData = {};
      await getUUID().then(res => {
        if (res.code === '0') {
          this.editData.uuid = res.data;
        }
      });
      this.editData.supplierCode = '';
      this.editData.supplierId = '';
      this.editData.supplierName = '';
      this.editData.prStatus = 'Draft';
      this.editType = 'add';
      this.isShowEdit = true;
      this.isEmptyData = true;
      this.isDisabledPull = false;
      this.isDisabledSupplier = false;
      this.isDisabledInventoryPull = false;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      if (this.prStatus == 'Draft') {
        this.getPurchaseRequestDraftList();
      } else {
        this.getPurchaseRequestList();
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      if (this.prStatus == 'Draft') {
        this.getPurchaseRequestDraftList();
      } else {
        this.getPurchaseRequestList();
      }
    },

    // 编辑组件函数
    updateEditModeSet(type, data, status) {
      // 编辑字段呈现方式集合
      const editModeSet = JSON.parse(JSON.stringify(this.editModeSet));
      const textSet = JSON.parse(JSON.stringify(this.textSet));
      const inputSet = JSON.parse(JSON.stringify(this.inputSet));
      const selectSet = JSON.parse(JSON.stringify(this.selectSet));
      const datePickerSet = JSON.parse(JSON.stringify(this.datePickerSet));
      const unitSet = JSON.parse(JSON.stringify(this.unitSet));
      if (type == 'edit') {
        switch (status) {
          case 'Draft':

            break;
          case 'Approved':

            break;
          default:
            break;
        }
      }
      editModeSet.textSet = textSet;
      editModeSet.inputSet = inputSet;
      editModeSet.selectSet = selectSet;
      editModeSet.datePickerSet = datePickerSet;
      editModeSet.unitSet = unitSet;
      data.editModeSet = editModeSet;
    },
    async inputChange(val, item, fieldItem) {
      const editListCopy = JSON.parse(JSON.stringify(this.editData.pricList));
      // this.isDisabledAddEdit = true;
      switch (fieldItem.field) {
        case 'materialCode':
          // 物料编号
          console.log('物料编号改变：', val, item, fieldItem);
          let businessType = '';
          item.productCode = '';
          item.supplierName = '';
          item.supplierId = '';
          item.supplierCode = '';
          item.supplierShortName = '';
          if (!item.materialCode) {
            this.$message({
              type: 'error',
              message: '请输入物料编号！'
            });
            return false;
          }
          // this.inputSetClassHandle(item, 'materialCode', '');
          if (this.editData.virtualInventoryId == 'C0') {
            businessType = '1';
          } else if (this.editData.virtualInventoryId == 'B0') {
            businessType = '2';
          }
          for (var i = 0; i < editListCopy.length; i++) {
            if (editListCopy[i].uuid == item.uuid) {
              const data = editListCopy[i];
              const sqParams = {
                businessType: businessType,
                unit: 'W',
                startTime: getNowDate(),
                materialCode: data.materialCode
              };
              await existMaterialCodeCache(data.materialCode).then(eRes => {
                console.log('验证物料编号：', eRes);
                if (eRes.code === '0') {
                  data.productCode = '';
                  if (!eRes.data) {
                    this.$message({
                      type: 'error',
                      message: '查无此物料编号！'
                    });
                    data.materialCode = '';
                    data.productCode = '';
                    return false;
                  }

                  // 根据物料编号查询产品编号
                  // this.getProductCodeHandle(data);
                  this.getBatchQuery([data], 'inputChange');
                  // getProductCodeByMC(data.materialCode, '', data.productCode).then(res => {
                  //   console.log('根据物料编号查询产品编号：', res);
                  //   if (res.code === '0') {
                  //     if (!res.data || !res.data.length) {
                  //       this.$message({
                  //         type: 'error',
                  //         message: '该物料编号下未查询到产品编号！'
                  //       });
                  //       return false;
                  //     }
                  //     let pcArr = [];
                  //     for (var i = 0; i < res.data.length; i++) {
                  //       pcArr.push(res.data[i].productCode);
                  //     }
                  //     data.productCode = pcArr.join(',');
                  //     data.productCodeList = res.data;
                  //     this.$forceUpdate();
                  //   }
                  // });

                  // setTimeout(function() {
                  if (!eRes.data.supplierName && !eRes.data.supplierId) {
                    data.supplierName = '';
                    data.supplierId = '';
                    data.supplierShortName = '';
                    this.$message({
                      type: 'error',
                      message: '该物料编号下无供应商！'
                    });
                    return false;
                  }
                  data.supplierName = eRes.data.supplierName;
                  data.supplierId = eRes.data.supplierId;
                  data.supplierShortName = eRes.data.supplierShortName;
                  data.supplierCode = eRes.data.supplierId;

                  // if (data.supplierId !== this.editData.supplierId) {
                  //   this.inputSetClassHandle(data, 'materialCode', 'danger');
                  //   this.$message({
                  //     type: 'error',
                  //     message: `该物料编号不属于${this.editData.supplierName}供应商，请确认后再添加！`
                  //   });
                  // } else {
                  //   this.inputSetClassHandle(data, 'materialCode', '');
                  // }
                  // }, 1000);

                  // 计量单位
                  this.getMeasurementUnit(data);

                  // 查询建议采购数量
                  searchSuggestQuantity(sqParams).then(res => {
                    // console.log('查询建议采购数量：', res);
                    if (res.code === '0' && res.data) {
                      data.suggestQuantity = res.data.readyPlanQuantity;
                    }
                  });
                }
              });
            }
          }
          this.editData.pricList = editListCopy;
          // this.isDisabledAddEdit = false;
          this.$forceUpdate();
          break;

        default:
          break;
      }
    },
    inputBlur(val, item, fieldItem) {
      let time = null;
      const _this = this;
      const editListCopy = JSON.parse(JSON.stringify(this.editData.pricList));
      switch (fieldItem.field) {
        case 'materialCode':
          // 物料编号
          console.log('物料编号失焦：', val, item, fieldItem);
          time = setTimeout(function() {
            if (!item.productCode || !item.supplierId) {
              _this.inputChange(val, item, fieldItem);
            }
            clearTimeout(time);
          }, 1000);
          break;

        default:
          break;
      }
    },
    selectChange(val, item, fieldItem) {
      switch (fieldItem.field) {
        case 'subInventory':
          // 库位
          console.log('库位值改变：', val, item, fieldItem);
          if (val) {
            const subInventoryData = item.subInventoryList.find(sItem => {
              return sItem.subInventory == val;
            });
            item.subInventoryName = subInventoryData.subInventoryName;
          } else {
            item.subInventoryName = '请选择';
          }
          break;

        default:
          break;
      }
    },
    selectVisibleChange(val, item, fieldItem) {
      switch (fieldItem.field) {
        case 'subInventory':
          // 库位
          console.log('库位值显隐：', val, item, fieldItem);
          item.subInventoryList = this.subInventoryList;
          break;

        default:
          break;
      }
    },
    unitNumberInputChange(val, item, fieldItem, step) {
      console.log('unitNumberInput值改变：', val, item, fieldItem, step);
      let field = '';
      if (step == 'first') {
        field = fieldItem.field_1;
      } else if (step == 'second') {
        field = fieldItem.field_2;
      }
      switch (field) {
        case 'requiredPackages':
          // 请求数量包数
          console.log('请求数量包数值改变：', val, item, fieldItem, step);
          item.requiredQuantity = (Number(item.requiredPackages ? item.requiredPackages : 0) * Number(item.requiredQuantityPerPackage ? item.requiredQuantityPerPackage : 0)).toFixed(2);
          item.requiredPackagesumy = this.getrequiredPackagesumy(item);
          this.$forceUpdate();
          break;
        case 'requiredQuantityPerPackage':
          // 请求数量规格
          console.log('请求数量规格改变：', val, item, fieldItem, step);
          item.requiredQuantity = (Number(item.requiredPackages ? item.requiredPackages : 0) * Number(item.requiredQuantityPerPackage ? item.requiredQuantityPerPackage : 0)).toFixed(2);
          item.requiredPackagesumy = this.getrequiredPackagesumy(item);
          this.$forceUpdate();
          break;
        default:
          item.requiredPackagesumy = this.getrequiredPackagesumy(item);
          this.$forceUpdate();
      }
    },
    // 产品编号远程搜索
    selectRemoteMethod(query, item, fieldItem) {
      switch (fieldItem.field) {
        case 'productCode':
          // 产品编号
          console.log('产品编号远程搜索：', query, item, fieldItem);
          if (query !== '' && query != undefined && item.materialCode) {
            this.selectRemoteLoading = true;
            setTimeout(() => {
              this.selectRemoteLoading = false;
              // 通过物料编号获取产品编号
              getProductCodeByMC(item.materialCode, '', query).then(res => {
                if (res.code === '0') {
                  item.productCodeList = res.data;
                  item.productCodeList = res.data.filter(pItem => {
                    return pItem.productCode.toLowerCase().indexOf(query.toLowerCase()) > -1;
                  });
                  this.$forceUpdate();
                }
              });
            }, 500);
          } else {
            item.productCodeList = [];
          }
          break;

        default:
          break;
      }
    },
    selectRemoteChange(query, item, fieldItem) {
      switch (fieldItem.field) {
        case 'productCode':
          // 产品编号
          console.log('产品编号改变：', query, item, fieldItem);
          break;

        default:
          break;
      }
    },
    selectRemoteClick(event, item, fieldItem) {
      switch (fieldItem.field) {
        case 'productCode':
          // 产品编号
          console.log('产品编号点击：', event, item, fieldItem);
          if (!item.productCode && item.materialCode) {
            this.getProductCodeHandle(item);
          }
          break;

        default:
          break;
      }
    },
    editSelectionChange(val) {
      console.log('选择编辑项：', val);
    }

  }
};
</script>
<style lang="scss" scoped>
@import "../../../styles/index.scss";
.purchaseRequisition {
  .isremark{
    // padding-bottom:10px;
  }
  .isThrough{
    text-decoration:line-through;
  }
}
</style>
<style>
 .purchaseRequisition .plTableBox .el-table td{
   text-align: center;
 }
 .purchaseRequisition .table_list.el-table td, .table_list.el-table th{
   /* padding: 10px 0; */
 }
</style>

